import {useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import MainButton from '../../UI/main-button/MainButton';
import {date} from './interface/IBooking';
import moment from 'moment';
import toast, {Toaster} from 'react-hot-toast';
import {sendRequestSession} from './Booking.service';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch} from '../../hooks/hooks';
import { setLoading } from '../../store/userSlice';

const RequestPrivateSession = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {id} = useParams();
  const [dates, setDates] = useState<date[]>([
    {
      date: 'selectDate',
      startTime: 'setStart',
      endTime: 'setEnd',
    },
  ]);
  const [reason, setReason] = useState<string>('');
  const [errorReason, setErrorReason] = useState<string>('');
  const [errorDates, setErrorDates] = useState<string>('');

  const addDate = () => {
    console.log(dates);

    if (
      dates[dates.length - 1].date == 'selectDate' ||
      dates[dates.length - 1].startTime == 'setStart' ||
      dates[dates.length - 1].endTime == 'setEnd'
    ) {
      toast.error('you can add new time after filling previous time');
    } else {
      setErrorDates('');
      setDates([
        ...dates,
        {
          date: 'selectDate',
          startTime: 'setStart',
          endTime: 'setEnd',
        },
      ]);
    }
  };

  const removeDate = () => {
    setDates(dates.slice(0, -1));
  };

  const sendRequestSessionFN = async () => {
    setErrorReason('');
    setErrorDates('');
    if (reason == '') {
      setErrorReason('Reason Required');
    } else if (
      dates[dates.length - 1]?.date == 'selectDate' ||
      dates[dates.length - 1]?.startTime == 'setStart' ||
      dates[dates.length - 1]?.endTime == 'setEnd'
    ) {
      setErrorReason('Add all data');
    } else {
      dispatch(setLoading(true));
      let result = await sendRequestSession(id!, dates, reason);
      
      if (result?.response?.status) {
        toast.success(result?.response?.msg);
        //navigate(-1);
        window.history.back();
      } else {
        console.log('result', result?.response?.msg);
        toast.error(result?.response?.msg.replace(/requests\.\d+\.day/g , ' day ' ));
      }
      dispatch(setLoading(false));
    }
  };

  const handleTimePicked = (time: any) => {
    console.log('time.target.value', moment(time.target.value, 'HH:mm'));

    let Arr: date[] = [...dates];
    if (time.target.id.includes('start')) {
      Arr[time.target.id[0]].startTime = moment(
        time.target.value,
        'HH:mm',
      ).locale('en');
    } else {
      Arr[time.target.id[0]].endTime = moment(
        time.target.value,
        'HH:mm',
      ).locale('en');
    }

    setDates(Arr);
  };

  const handleDatePicked = (date: any) => {
    console.log(date.target.value);
    console.log(date.target.id);

    let Arr = [...dates];
    Arr[date.target.id].date = moment(date.target.value)
      .locale('en')
      .format('YYYY-MM-DD');
    setDates(Arr);
  };

  const currentDate = new Date().toISOString().split('T')[0];

  return (
    <div className="mt-md-5">
      <Container>
        <Toaster position="top-right" />
        <Row className="d-flex justify-content-between">
          {/*<Col xs={12} md={3}>{<ConsultantCard />}</Col>*/}

          <Col md={{span: 6, offset: 3}} >
            <form>
              <Row>
                <Col md="12">
                  <h4 className="mb-0">
                    <strong>Request Private Lesson</strong>
                  </h4>
                </Col>

                <Col xs={{span: 12}} md={{span: 12}}>
                  <div className="mt-3 mb-3">
                    <label className="mb-2">Reason</label>
                    <Form.Control
                      className={`form-control m-0`}
                      as="textarea"
                      rows={6}
                      onChange={(event: any) => setReason(event.target.value)}
                    />
                  </div>
                </Col>
                

                <Col md="12">
                  <h4 className="mt-3 mb-0">
                    <strong>Appropriate time and date</strong>
                  </h4>
                </Col>
              </Row>

              {dates.map((date, index) => (
                <Row key={index}>
                  <Col xs={{span: 12}} md={{span: 12}}>
                    <div className="mt-3 mb-3">
                      <label className="mb-2">Date</label>
                      <input
                        type="date"
                        className={`form-control m-0`}
                        id={String(index)}
                        placeholder="DD-mm-yyyy"
                        required
                        onChange={handleDatePicked}
                        min={currentDate}
                      />
                    </div>
                  </Col>

                  <Col xs={{span: 12}} md={{span: 6}}>
                    <div className="mt-3 mb-3">
                      <label className="mb-2">Start Time</label>
                      <input
                        type="time"
                        id={`${index}start`}
                        className={`form-control m-0`}
                        placeholder="DD-mm-yyyy"
                        required
                        onChange={handleTimePicked}
                      />
                    </div>
                  </Col>

                  <Col xs={{span: 12}} md={{span: 6}}>
                    <div className="mt-3 mb-3">
                      <label className="mb-2">End Time</label>
                      <input
                        type="time"
                        id={`${index}end`}
                        accessKey="xx"
                        className={`form-control m-0`}
                        placeholder="DD-mm-yyyy"
                        required
                        onChange={handleTimePicked}
                      />
                    </div>
                  </Col>
                </Row>
              ))}

              <div className="d-flex flex-column text-right mb-3 align-items-right">
                {dates.length > 1 && (
                  <span
                    style={{color: '#0576e3', cursor: 'pointer'}}
                    onClick={removeDate}>
                    Clear
                  </span>
                )}
                <span
                  style={{color: '#0576e3', cursor: 'pointer' , width : 'fit-content' , marginLeft : 'auto' }}
                  onClick={addDate}>
                  New Time
                </span>
              </div>

              <div className="mb-4">
              {errorReason != '' && (<div className='red'>{errorReason}</div>)}
              </div>

              <div className="mb-2">
                <MainButton
                  text="Send Request"
                  onClick={sendRequestSessionFN}
                />
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RequestPrivateSession;
