import moment from 'moment';

export const getDaysArray = dateRanges => {
  const allDates = [];

  for (const range of dateRanges) {
    const startDate = new Date(range[0]);
    const endDate = new Date(range[1]);

    let currentDate = startDate;

    while (currentDate <= endDate) {
      // const formattedDate = currentDate.toISOString().split('T')[0];
      const formattedDate = moment(currentDate).format('YYYY-MM-DD');
      allDates.push(formattedDate);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  return allDates;
};
