import { Card, Container } from "react-bootstrap";
import Story from "../../components/story/Story";
import { setLoading } from "../../store/userSlice";
import { useAppDispatch } from "../../hooks/hooks";
import { getStory } from "./stories.service";
import { useEffect, useRef, useState } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './styles/StoriesList.css'
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MyStories from './MyStories';

const GuestStoriesList = () => {

  const targetRef = useRef<HTMLInputElement>(null);

  const handleScrollClick = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });

    }
  };

    const [showStories, setShowStories] = useState(false);
    const [resetStories, setResetStories] = useState(false);

    const [activeUserStories, setActiveUserStories] = useState([]);

    const [showTopUsersStories, setShowTopUsersStories] = useState(true);

    const [userIndex, setUserIndex] = useState(0);

    const handleShowStories = () => {
    setResetStories(true);
    setShowStories(true);
    };

    const handleStoriesEnd = () => {
    setShowStories(false);
    setResetStories(false);
    setShowTopUsersStories(true)
    };

  

  const avatar = localStorage.getItem('avatar');

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: {max: 4000, min: 3000},
      items: 4,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 4,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 3,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 2,
    },
  };

  const dispatch = useAppDispatch()

  const navigate = useNavigate();

  const [data, setData ] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    
    dispatch(setLoading(true));
    let result = await getStory();
    if (result.response) {
      setData(result.response.data)
    }
    dispatch(setLoading(false));
  };

  return (
    <>
    <div 
    className="bg-story"
    style={{ height:'20rem' ,  background:'url(/story-bg.png)' , backgroundRepeat: 'no-repeat' , backgroundSize: '100% 100%' }}
    >
      <div className="text-white font-30 font-weight-700 py-md-5 text-center">Latest Stories</div>  
    </div>

    <div className="stories-list">
    {data.length ?  
    <> 
    { showTopUsersStories ? 
    <Container>
      { data.length &&  
        <Carousel responsive={responsive} infinite={true} >
        
          {data.map( (story:any,key) => {
            return (
              <div key={key} onClick={() => {
                navigate('/login')
                
              } }>
                <Story story={story} />
              </div>
            );
          })}
          
          
        </Carousel>
        
      }
    </Container>
    : '' }
    <div ref={targetRef}>
      <MyStories
      visible={showStories}
      onEnd={handleStoriesEnd}
      resetStories={resetStories}
      usersData={data}
      userIndex={userIndex}
    />
    </div>
    </>
    : ''}
    

  </div>

  </>
  );
};

export default GuestStoriesList;
