import {Col, Container, Row} from 'react-bootstrap';
import PersonalityAnalysisCard from './PersonalityAnalysisCard';
import {PersonalityAnalysisCardProp} from '../interface/IQuiz';
import MainButton from '../../../UI/main-button/MainButton';
import {useNavigate} from 'react-router-dom';
const UserQuizResult = ({report, title}: PersonalityAnalysisCardProp) => {
  const navigate = useNavigate();
  return (
    <Container>
      {/* personality analysis list     */}
      <section className="section">
        
        <div>
          <h1 className="main-black-title text-center">Congratulations</h1>
          <p className="text-center">You have { ( report?.percentage ?? 0 )  >=  ( report?.passing_percentage ?? 0 ) ? 'Passed' : 'Failed'} {title}</p>
          <Col md={3} className="m-auto">
            <PersonalityAnalysisCard report={report} title={title} />
          </Col>
          <div>
            <MainButton
              text={'Back to course'}
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </div>
      </section>
    </Container>
  );
};

export default UserQuizResult;
