import {Fragment, useRef} from 'react';

import {Button, Col, Container, Modal, Row} from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import HomeConsultantCard from '../../Screens/Consultants/components/HomeConsultantCard';
import './Home.css';
import CourseCard from '../../UI/CourseCard';
import InitiativeCard from '../../UI/initiative-card/InitiativeCard';
import Story from '../../components/story/Story';
import BankQuestionsContent from '../../components/bank-questions-content/BankQuestionsContent';
import MainButton from '../../UI/main-button/MainButton';
import TrackingCard from '../../UI/tracking-card/TrackingCard';
import {useLocation, useNavigate} from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {NavLink} from 'react-router-dom';
import FollowupCard from '../../UI/followup-card/InitiativeCard';
import {useEffect, useState} from 'react';
import {getHomeModules} from './Home.service';
import BankQuestionItem from './BankQuestionItem';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {changeNotificationNumber, setConsStatus} from '../../store/userSlice';
import {isUserLoggedIn} from '../../auth/utiles';
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';
import {database} from '../../Screens/Consultants/components/firebase';
import {
  ref,
  orderByKey,
  endAt,
  get,
  limitToLast,
  query,
  onValue,
  off,
  update,
  set,
} from 'firebase/database';
import { getChatDomain } from '../../helpers/getChatDomain';
import Sliders from './Sliders';
import Banners from './Banners';
import Achievements from './Achievements';
import CancelButton from '../../UI/cancel-button/CancelButton';
import PopUpAds from './PopUpAds';
import HomeTeachers from '../../Screens/Consultants/HomeTeachers';
import StoriesList from '../../Screens/stories/StoriesList';
import GuestStoriesList from '../../Screens/stories/GuestStoriesList';
import ListCourseCard from '../../UI/ListCourseCard';
const Home = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const [data, setData] = useState(null);
  const consStatus = useAppSelector(state => state.user.consStatus);
  const location = useLocation();

  const isCons = localStorage.getItem('isCons');

  const Logged = isUserLoggedIn();

  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const [activeConsultantTab, setActiveConsultantTab] = useState('consultants');
  const [activeInitiativesTab, setActiveInitiativesTab] =
    useState('initiatives');
  const [activeFollowUpTab, setActiveFollowUpTab] = useState('single');
  const [activeCoursesTab, setActiveCoursesTab] = useState('recorded');

  const chatListen = async () => {
    let myId = localStorage.getItem('id');
    let refPath = ref(database, `${getChatDomain()}/${myId}`);
    onValue(refPath, snap => {
      let val = snap.val();
      if (!val) {
        dispatch(changeNotificationNumber(0));
      }

      const messenger = val;
      let readCount = 0;
      Object.values(messenger).forEach(lastObject => {
        const keys = Object.keys(lastObject);
        let lastMessage = Object.keys(lastObject).sort((a, b) => b - a)[
          Object.keys(lastObject).length - 1
        ];
        const lastItem = lastObject[lastMessage];
        if (lastItem.notRead) {
          readCount++;
        }

        dispatch(changeNotificationNumber(readCount));
      });
    });
  };

  useEffect(() => {
    getData();
    chatListen();
  }, []);
  useEffect(() => {
    setIsLoggedIn(Logged);
  }, [location.pathname]);

  const [popUpAdsArray, setPopUpAdsArray] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);

  const getData = async () => {
    let result = await getHomeModules(i18n.resolvedLanguage, Intl.DateTimeFormat().resolvedOptions().timeZone);
    console.log('home response', result.response);
    if (result.response.status) {
      dispatch(
        setConsStatus(result?.response?.data?.consultation_request_status),
      );
      setData(result.response.data);
      if(result.response.ads){
        setPopUpAdsArray(result.response.ads)
        setShowPopUp(true)
      }
    }
  };

  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: {max: 4000, min: 3000},
      items: 5,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 4,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 2,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  };
  

  return (
    <Fragment>
      

      {/*<img className="d-block w-100" src="/banner-en.jpg" alt="First slide"></img>*/}

      
     
     { Logged ? <StoriesList /> : <GuestStoriesList /> }

     { data?.courses.length > 0  ? 
      <div
        className="pt-md-5 pb-md-5 mb-5"
        style={{background: '#EEF4F4'}}>
        <div className='container'>
          <Row>
            <Col
              lg="12"
              className="mb-4 d-flex justify-content-between align-items-center px-md-4">
              
              <h1
                className="m-0 text-center"
                style={{fontWeight: '700', color: '#1E1E1E',fontSize:'23px'}}>
                {t('Courses')}
              </h1>
              
              <NavLink
                to={'/courses'}
                className={'see-more'}>
                {t('See More')} <img src="/arrow-angle.png" alt="image" />
              </NavLink>
            </Col>

            {/*
            <Col lg="12" className="mb-md-5">
              <div className="text-center">
                <button
                  className={`custom-tab-button ${
                    activeCoursesTab == 'recorded' ? 'active' : ''
                  }`}
                  onClick={() => setActiveCoursesTab('recorded')}>
                  {t('Recorded Courses')}
                </button>
                <button
                  className={`custom-tab-button ${
                    activeCoursesTab == 'live' ? 'active' : ''
                  }`}
                  onClick={() => setActiveCoursesTab('live')}>
                  {t('Live Courses')}
                </button>
              </div>
            </Col>
            */}

           
              <div>
                {(data?.courses.length > 0) && (
                  <Carousel showDots={true} responsive={responsive} infinite={true}>
                    { data?.courses.length > 0 && data?.courses.map(course => (
                      <div className="carouselItem">
                        <ListCourseCard course={course} />
                      </div>
                    ))}
                    
                  </Carousel>
                )}
              </div>
           
          </Row>
        </div>
      </div>
      : '' }


      <HomeTeachers />


      <Banners />

      {/*pop up ad*/}
      <Modal
        show={showPopUp}
        onHide={() => setShowPopUp(false)}
        centered>
        <Modal.Header>
          
        </Modal.Header>
        <Modal.Body>
       <PopUpAds
       ads={popUpAdsArray}
       />
        </Modal.Body>

        <Modal.Footer>
          <div onClick={() => setShowPopUp(false)} className="w-100">
            <CancelButton text="Dismiss" />
          </div>
        </Modal.Footer>
      </Modal>
     
    </Fragment>
  );
};

export default Home;
