import React, { useState } from 'react';
import { Collapse } from "react-bootstrap"
import { FaCalendar, FaClock, FaMinus, FaPlus } from 'react-icons/fa';
import './LiveCourseContent.css'
import LiveCourseContentObj from './LiveCourseContentObj';


    const LiveCourseContent = () => {

        const [ courseSections , setCourseSections ] = useState(LiveCourseContentObj)

        const changeCollapeHandler = (index) => {

        let newCourseSections = [...courseSections]
        
        console.log(newCourseSections[index])

        newCourseSections[index].collapsed = ! newCourseSections[index].collapsed

            
        setCourseSections(newCourseSections)
        
    }

    

    

    
    return (
        <div className="course-content">
            <div className='course-header'>
                <h5 className='mt-4'>Live Course Content</h5>
                <p className="m-0">3 sections, 15 lectures</p>
            </div>

            <div className="course-content-sections">

                {
                    courseSections.map( (section,index) => {

                       return (

                        <div className='section-container mb-4' key={index}>    

                            <div
                                onClick={() => changeCollapeHandler(index)}
                                aria-controls="example-collapse-text"
                                aria-expanded={section.collapsed}
                                className="d-flex justify-content-between align-items-center section-item-collapse"
                            >
                                <p className='section-title'>{section.title}</p>
                                <div>{ section.collapsed ? <FaMinus /> : <FaPlus /> }</div>
                                
                            </div>
                            <Collapse in={section.collapsed}>
                                <div id="example-collapse-text" >
                                    {
                                        section.lessons.map( (lesson,indexs) => {
                                            return (
                                                
                                                
                                                <div className="section-item d-flex justify-content-between" key={indexs}>
                                                    <div className="icons-container d-flex">
                                                        <div>
                                                            <a href={lesson.url} target='_blank' rel="noreferrer" >
                                                                <img
                                                                    src="/icons/zoom.png"
                                                                    style={ { 'background': '#ffedee' , 'borderRadius': '15px' , 'padding': '12px' } } 
                                                                    alt="img"
                                                                />
                                                            </a>
                                                        </div>

                                                        <div>
                                                            <p className="lecture-title">
                                                                <p><a href={lesson.url} target='_blank' rel="noreferrer" >{lesson.title}</a></p>
                                                                <p>
                                                                    <a className='d-flex live-date' href={lesson.url} target='_blank' rel="noreferrer" >
                                                                        <span className='d-flex align-items-center'>
                                                                            <FaCalendar color='#0576e3'  style={ { 'marginRight': '5px' } } />
                                                                            {lesson.day}
                                                                        </span> 

                                                                        <span className='d-flex align-items-center' style={ { 'marginLeft': '1rem'  } }>
                                                                            <FaClock style={ { 'marginRight': '5px' } } color='#0576e3' />
                                                                            {lesson.from} - {lesson.to}
                                                                        </span>
                                                                    </a>
                                                                </p>
                                                            </p>

                                                            {/* <p className="lecture-type">
                                                                <a href={lesson.url} target='_blank' rel="noreferrer" >meet</a>  
                                                            </p> */}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        {/* <FaLock className='lock-icon' /> */}
                                                        <img src='/icons/lock.png' alt="img" width={25}/>
                                                    </div>

                                                </div> 
                                               

                                            )
                                        } )
                                    }
                                                             
                                </div>

                                

                                
                            </Collapse>
                        </div>

                       )

                    } )
                }

            </div>
        </div>
    )
}

export default LiveCourseContent