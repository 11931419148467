import {Col, Container, Row} from 'react-bootstrap';
import './LiveCourseDetails.css';
import LiveCourseSections from '../../components/live-course-sections/LiveCourseSections';
import CourseFeedback from '../../Screens/Courses/CourseFeedback';
import Comments from './components/comments/Comments';
import CourseCard from './components/course-card/CourseCard';

const LiveCourseDetails = () => {
  return (
    <div>
      <div className="header d-flex">
        <Container className="d-flex align-items-center">
          <Row className="d-flex align-items-center">
            <Col xs={6} className="d-flex w-100">
              <div className="breadcrumb d-flex justify-content-center">
                <h3 className="breadcrumb-header">Courses</h3>
                <div className="breadcrumb-details">
                  <span>Home</span> <span> - </span>
                  <span>Courses</span> <span> - </span>
                  <span className="active-page">Live</span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row className="d-flex justify-content-between">
          <Col xs={{span: 12, order: 2}} md={{span: 8, order: 1}}>
            <LiveCourseSections />

            <CourseFeedback />

            <Comments />
          </Col>

          <Col xs={{span: 12, order: 1}} md={{span: 4, order: 2}}>
            <CourseCard />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LiveCourseDetails;
