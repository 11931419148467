import { Col, Container, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { useState } from "react";
import MainButton from "../../UI/main-button/MainButton";
import CustomCheckbox from "./components/CustomCheckbox";
const AssignFamily = () => {
    const [show, setShow] = useState<boolean>(true);

    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const handleCheckboxChange = (event:any) => {
    const { value, checked } = event.target;
    console.log('value, checked', value, checked )
    if (checked) {
        // If checkbox is checked, add the value to the selectedValues array
        setSelectedValues((prevSelectedValues) => [...prevSelectedValues, value]);
    } else {
        // If checkbox is unchecked, remove the value from the selectedValues array
        setSelectedValues((prevSelectedValues) =>
        prevSelectedValues.filter((item) => item !== value)
        );
    }

    console.log('selectedValues' , selectedValues )
    //const selectedObjects = checkboxes.filter(obj => selectedValues.includes(obj.value));
    //console.log('selectedObjects' , selectedObjects )

    };
    
    const checkboxes = [
    { label: 'Ahmed Taha', value:'1' , avatar : 'https://uploads.nurabi.net/uploads/Eq0gM3xNsRjaiYlm99ZzLPEmrV6Tv6J8Hccz5OHf.jpg' , assigned: true },
    { label: 'Ahmed Hamam', value: '2' , avatar : 'https://uploads.nurabi.net/uploads/Eq0gM3xNsRjaiYlm99ZzLPEmrV6Tv6J8Hccz5OHf.jpg' , assigned: false },
    { label: 'Ahmed Elsaid', value: '3' , avatar : 'https://uploads.nurabi.net/uploads/Eq0gM3xNsRjaiYlm99ZzLPEmrV6Tv6J8Hccz5OHf.jpg' , assigned: false },
    ];
   
  return (
  
        <Container className="mt-5">
            <Row>
                <Col md={{span: 6 , offset :3}}>
                    <p style={{ fontSize: '18px'}} className="mb-4">You can assign this system to one or more members of your family</p>

                    

                    <div className="box-border mb-4">
                        <div className="d-flex justify-content-between cursor-pointer" onClick={ () => setShow(!show) }>
                            <h5>Family Memebers</h5>
                            <div>
                                <img
                                src='/icons/down-arrow.png' 
                                style={{ 
                                    width: '15px',
                                    transform: show ? 'rotate(180deg)' : 'rotate(00deg)'
                                }} 
                                />
                            </div>

                        </div>
                        <hr style={{ paddingBottom: '1rem' }} />
                        { show && <div>
                            {
                                checkboxes.map((checkbox,i) => (
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <img 
                                        src={checkbox.avatar}
                                        alt='image'
                                        style={{
                                            width: '60px',
                                            borderRadius: '50%',
                                            height: '60px',
                                            margin:'0 20px 0 0',
                                        }}
                                        />
                                        <span>{checkbox.label}</span>
                                    </div>

                                    <div>


                                    <div>
                                        <CustomCheckbox
                                            index={i}
                                            value={checkbox.value}
                                            checked={selectedValues.includes(checkbox.value)}
                                            handleCheckboxChange={handleCheckboxChange}
                                        />
                                    </div>

                                    

                                    </div>    
                                </div>
                                ))
                            }
                            </div>
                        }   
                    </div>

                    <div className="box-border mb-3">
                        <h4 className="secondry-color mb-4">Selected Users</h4>
                        {
                            selectedValues.map(value => {
                                return (
                                    <div className="mb-3">
                                        <img 
                                        src={ checkboxes.find( item => item.value == value )?.avatar }
                                        alt='image'
                                        style={{
                                            width: '60px',
                                            borderRadius: '50%',
                                            height: '60px',
                                            margin:'0 20px 0 0',
                                        }}
                                        />
                                        <span>{ checkboxes.find( item => item.value == value )?.label }</span>
                                    </div>
                                )
                            } )
                        }
                    </div>

                    <div className="box-border mb-4">
                        <h4 className="secondry-color mb-4">Assigned Users</h4>
                        {
                            checkboxes.filter((i) => i.assigned == true ).map(item => {
                                return (
                                    
                                    <div className="mb-3">
                                        <img 
                                        src={ item.avatar }
                                        alt='image'
                                        style={{
                                            width: '60px',
                                            borderRadius: '50%',
                                            height: '60px',
                                            margin:'0 20px 0 0',
                                        }}
                                        />
                                        <span>{ item.label }</span>
                                    </div>
                                )
                            } )
                        }
                    </div>

                    <div>
                        <MainButton text={'Save'} />
                    </div>

                </Col>
            </Row>
        </Container>
    
  );
}

export default AssignFamily;