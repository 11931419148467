export const getChatDomain = () => {
  
    let chatDomainName =''
    let hostName = window.location.hostname

    if( hostName == 'localhost' || hostName == 'dev.nualim.com' ){
        chatDomainName = 'DEV'
    }

    else if( hostName == 'test.nualim.com' ){
        chatDomainName = 'TEST'
    }

    else if( hostName == 'prod.nualim.com' || hostName == 'nualim.com' ){
        chatDomainName = 'Chats'
    }
    
    else{
        chatDomainName = 'DEV'
    }
    
    
    return chatDomainName
  
};
