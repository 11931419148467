import {APIURL} from '../../services/http/api';
import {HttpService} from '../../services/http/http.service';
import {EApiParams} from '../../services/http/apiParams.enum';
import {urlParamReplace} from '../../helpers/UrlParamReplace';

const http = new HttpService();

export const getQuizData = async (id: string | number | undefined) => {
  const api = APIURL.courses.getQuiz;
  const finalApi = urlParamReplace(api, {[EApiParams.id]: id});
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};

export const submitQuiz = async (data: any) => {
  const api = APIURL.courses.submitQuiz;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};
