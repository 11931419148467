import './style/CoursesFilter.css';
import Col from 'react-bootstrap/Col';
import {CheckBoxTitle} from '../../UI/check-box-title/CheckBoxTitle';
import {useEffect} from 'react';
import {categories, insertCategoryList} from '../../store/coursesSlice';
import {insertLanguages} from '../../store/LanguageSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {Enums} from '../../helpers/Enums';
import {FilterProps} from './interfaces/ICourses';
import { Dropdown } from 'react-bootstrap';
import ActionButton from '../../UI/action-button/ActionButton';

const ListCoursesFilter = ({
  setLearnType,
  learnType,
  setCategoriesSelected,
  categoriesSelected,
  setLanguagesSelected,
  languagesSelected,
  setCourseProviderSelected,
  courseProviderSelected,
  setPricing,
  pricing,
  courseProvider,
  setPage,
}: FilterProps) => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector(state => state.courses.categories);
  const languages = useAppSelector(state => state.language.languages);

  useEffect(() => {
    dispatch(insertCategoryList());
    dispatch(insertLanguages());
  }, []);

  const Check = (type: string[], setType: any, key: string) => {
    setPage(1);
    type.includes(key)
      ? setType(type.filter((i: string | number) => i != key))
      : setType([...type, key]);
  };

  const Check2 = (type: number[], setType: any, key: number) => {
    setPage(1);
    if (type?.includes(key)) {
      setType(type?.filter((item: number) => item != key));
    } else {
      let data = [...type];
      data.push(key);
      setType(data);
    }
  };

  return (
    <div>
       <div className="top-filter-container shareDropdown d-flex">
          <Dropdown>
            <Dropdown.Toggle variant="customSocialShareDropDown" id="dropdown-custom">
              <span className='top-filter-type'>Type</span> <img src="/icons/arrow-down.svg" className="mx-2" alt="img" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
            
            <div className="filter-type-options">
              <CheckBoxTitle
                  id={'online-courses'}
                  checked={learnType.includes(Enums.recorded)}
                  title={Enums.Recorded}
                  setChecked={() => {
                    Check(learnType, setLearnType, Enums.recorded);
                  }}
                />
                <CheckBoxTitle
                id={'live-courses'}
                checked={learnType.includes(Enums.live)}
                title={Enums.Live}
                setChecked={() => {
                  Check(learnType, setLearnType, Enums.live);
                }}
              />
              </div>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle variant="customSocialShareDropDown" id="dropdown-custom">
              <span className='top-filter-type'>Categories</span> <img src="/icons/arrow-down.svg" className="mx-2" alt="img" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
            
              
            <div className="filter-type-options">
            {categories?.map((category: categories) => {
              return (
                <CheckBoxTitle
                  id={category?.name}
                  checked={categoriesSelected?.includes(category.id)}
                  title={category?.name}
                  setChecked={() => {
                    if (categoriesSelected?.includes(category?.id)) {
                      setCategoriesSelected(
                        categoriesSelected?.filter(
                          item => item != category?.id,
                        ),
                      );
                    } else {
                      let data = [...categoriesSelected];
                      data.push(category.id);
                      setCategoriesSelected(data);
                    }
                  }}
                />
              );
            })}
          </div>
            </Dropdown.Menu>
          </Dropdown>

          {/*  
          <Dropdown>
            <Dropdown.Toggle variant="customSocialShareDropDown" id="dropdown-custom">
              <span className='top-filter-type'>Course Provider</span> <img src="/icons/arrow-down.svg" className="mx-2" alt="img" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
            
              
            <div className="filter-type-options">
            {courseProvider.map(i => {
              return (
                <CheckBoxTitle
                  id={i?.name}
                  checked={courseProviderSelected.includes(i.id)}
                  title={i?.name}
                  setChecked={() => {
                    Check2(
                      courseProviderSelected,
                      setCourseProviderSelected,
                      i.id,
                    );
                  }}
                />
              );
            })}
          </div>
            </Dropdown.Menu>
          </Dropdown>
          */}

          <Dropdown>
            <Dropdown.Toggle variant="customSocialShareDropDown" id="dropdown-custom">
              <span className='top-filter-type'>Language</span> <img src="/icons/arrow-down.svg" className="mx-2" alt="img" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
            
              
            <div className="filter-type-options">
            {languages.map(i => {
              return (
                <CheckBoxTitle
                  id={i?.name}
                  checked={languagesSelected?.includes(i.id)}
                  title={i?.name}
                  setChecked={() => {
                    Check2(languagesSelected, setLanguagesSelected, i?.id);
                  }}
                />
              );
            })}
          </div>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle variant="customSocialShareDropDown" id="dropdown-custom">
              <span className='top-filter-type'>Price</span> <img src="/icons/arrow-down.svg" className="mx-2" alt="img" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
            
            <div className="filter-type-options">
            <CheckBoxTitle
              id={'Free'}
              checked={pricing?.includes(Enums.free)}
              title={'Free'}
              setChecked={() => {
                Check(pricing, setPricing, Enums.free);
              }}
            />

            <CheckBoxTitle
              id={'Paid'}
              checked={pricing.includes(Enums.paid)}
              title={'Paid'}
              setChecked={() => {
                Check(pricing, setPricing, Enums.paid);
              }}
            />
              </div>
            </Dropdown.Menu>
          </Dropdown>
      </div>
    </div>
  );
};

export default ListCoursesFilter;
