import {Container} from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import ConsultantProfile from '../ConsultantProfile/ConsultantProfile';
import classes from './style/ConsultantPages.module.css';
import './style/ConsultantPages.css';
import ConsultantProfileCourses from '../Consultants/components/ConsultantProfileCourses';
import ConsultantFeedback from '../Consultants/components/ConsultantFeedback';
import UserSessions from '../Sessions/UserSessions';
import PrivateSessionsList from '../Booking/PrivateSessionsList';
import RateStars from '../../UI/rate-stars/RateStars';
import RateStarsEdit from '../../UI/rate-stars-edit/RateStarsEdit';
import {isUserLoggedIn} from '../../auth/utiles';
import {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {getUserData} from '../Auth/Auth.service';

const ConsultantPages = () => {
  const [feedback, setFeedBack] = useState([]);
  const [ratingStars, setRatingSatingStars] = useState([]);

  const avatar = localStorage.getItem('avatar');
  const name = localStorage.getItem('name');
  const email = localStorage.getItem('email');
  const id = localStorage.getItem('id');
  const stars = JSON.parse(localStorage.getItem('stars'));

  const isLoggedIn = isUserLoggedIn();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let result = await getUserData();
    console.log('result.response', result.response);
    console.log(
      'result.responsefeedBack222',
      result?.response?.user?.consultation_info,
    );
    if (result.response.status) {
      setFeedBack(result?.response?.user?.consultation_info?.feedBack);
      setRatingSatingStars(
        result?.response?.user?.consultation_info?.rating_stars,
      );
    }
  };

  const logout = () => {
    localStorage.removeItem('logged');
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('avatar');
    localStorage.removeItem('id');
    localStorage.removeItem('isCons');
    localStorage.removeItem('switch');
    window.location.replace('/');
  };

  return (
    <div className={`${classes.ConsultantPages} consultant-pages`}>
      <Container>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={3}>
              <div className="top-head-sidebar d-flex align-items-center">
                <div>
                  <img
                    src={avatar != 'null' ? avatar : '/icons/manHolder.png'}
                    style={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '50%',
                      marginRight: '15px',
                    }}
                  />
                </div>
                <div className="d-flex" style={{flexDirection: 'column'}}>
                  <h5 className="mt-0 mb-1">{name}</h5>
                  <p
                    className="m-0"
                    style={{color: 'gray', lineBreak: 'anywhere'}}>
                    {email}
                  </p>
                </div>
              </div>

              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Account Details</Nav.Link>
                </Nav.Item>

                {/*
                    <Nav.Item>
                        <Nav.Link eventKey="courses">Courses</Nav.Link>
                    </Nav.Item>
                    */}
                <Nav.Item>
                  <Nav.Link eventKey="reviews">Reviews</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  {isLoggedIn && (
                    <div onClick={() => logout()}>
                      <NavLink to="#" className="nav-link">
                        <span style={{color: '#979797'}}>Logout</span>
                      </NavLink>
                    </div>
                  )}
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <h3>Account Details</h3>
                  <ConsultantProfile />
                </Tab.Pane>
                <Tab.Pane eventKey="courses">
                  <h3 className="mb-4">Courses</h3>
                  <ConsultantProfileCourses id={id} />
                </Tab.Pane>
                <Tab.Pane eventKey="reviews">
                  <ConsultantFeedback
                    rating_stars={ratingStars}
                    feedback={feedback}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default ConsultantPages;
