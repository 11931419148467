import {Card, Col, Container, Row, Tab, Tabs} from 'react-bootstrap';
import classes from './style/TrackingDetailsAfterEnroll.module.css';
import {useParams, useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {FaCircle, FaEdit, FaFile, FaFileAlt, FaFileArchive, FaFileCode, FaFileSignature, FaFileUpload, FaPaperclip, FaPlay, FaPlaystation, FaRegCheckSquare, FaRegEdit, FaRegFileAlt, FaYoutube, FaYoutubeSquare} from 'react-icons/fa';
import { IoGameControllerOutline } from "react-icons/io5";

import practicalTaskClasses from '../Courses/style/PracticalTaskUplaod.module.css';
import {NavLink} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import MainButton from '../../UI/main-button/MainButton';
import CancelButton from '../../UI/cancel-button/CancelButton';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {
  addTrackNote,
  editTrackNote,
  sendInitiativeProf,
  updateProgress,
  updateProgressAutomatic,
} from '../Tracking/Trackings.service';
import { useAppDispatch , useAppSelector } from '../../hooks/hooks';
import { setLoading } from '../../store/userSlice';

import {getActivityData} from './Initiatives.service';

import moment from 'moment';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import YouTube from 'react-youtube';
import InitiativeDetailsCard from './InitiativeDetailsCard';
import toast, {Toaster} from 'react-hot-toast';
import { getDomain } from '../../helpers/getDomain';
import CircleProgress from '../../UI/circle-progress/CircleProgress';
import { colors } from '../../constants/colors';
import ZeroProgressPopover from './components/ZeroProgressPopover';
import { t } from 'i18next';
import { getAllTaskNotes, getTaskDetails, sendAttach } from '../Auth/tasks.services';

const UserTaskAddProgress = () => {
  const videoOptions = {
    playerVars: {
      // You can add additional YouTube player options here
      autoplay: 0,
    },
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [lang, setLang] = useState(queryParams.get('lang') || 'en');

  
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);

  
  const [progressVal, setProgressVal] = useState<any>(1);
  const [showProgressVal, setShowProgressVal] = useState<any>(false);

  const [showGame, setShowGame] = useState<boolean>(false);

  const [confirmUpdate, setConfirmUpdate] = useState<boolean>(false);

  const [showAddNote, setShowAddNote] = useState<boolean>(false);

  const [confirmDeleteNote, setConfirmDeleteNote] = useState<boolean>(false);

  const [showAllNotes, setShowAllNotes] = useState<boolean>(false);

  const [allNote, setAllNote] = useState<any>([]);
  
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { id , memberId } = useParams();

  const [activityData, setActivityData] = useState<any>();

  const [selectedNoteId, setSelectedNoteId] = useState<any>();

  const [selectedNoteValue, setSelectedNoteIdValue] = useState<any>();

  const [profMidea, setprofMidea] = useState<any>();
  const [attach, setAttach] = useState<any>();
  
  //const daySelected = location.state.daySelected;
  const today = moment().locale('en').format('YYYY-MM-DD');

  const [userActivityId, setUserActivityId] = useState(null);

  const [showUpdateProgress, setShowUpdateProgress] = useState<boolean>(false);

  const [progress, setProgress] = useState<number>(0);

  const [styleProgress, setStyleProgress] = useState<number>(0);

  const [autoUpdate, setAutoUpdate] = useState<number>(1);

  const [showYoutubeTask, setShowYoutubeTask] = useState<boolean>(false);
  

  const getData = async () => {
    
    dispatch(setLoading(true));
    let data = new FormData();
    data.append('family_member_id', String(memberId));
    let result = await getTaskDetails(id,data)
    if (result.response) {
      dispatch(setLoading(false));
      
      
      setActivityData(result.response.data);
      console.log('tasks details result.response.data')
      
      setprofMidea(
        result.response.data?.task_media?.profs.length
          ? result.response.data?.task_media?.profs[0].media_path
          : null,
      );
      
    }
  };

  const handleFileChange = async (event: any) => {
    
    const file = event.target.files[0];
    const fileExtension = file.name.split('.').pop();
 
    const allExt = ['png','PNG','jpg','JPG','jpeg','JPEG','webp','WEBP','svg','SVG','mp4','MP4','mpg','mpeg','avi','wmv','mov','rm','ram','OGG','ogg','webm','WEBM']
    
    if (allExt.includes(fileExtension)) {
      
      setShowProgressVal(true)
      dispatch(setLoading(true));
      setSelectedFile(file);

      

      let data = new FormData();
      data.append('user_activity_id', activityData.task_user_activity_id);
      data.append('activity_id', String(id));

      data.append('prof', activityData?.media?.profs[0]?.id ?? 0);

      if (
        [
          'png',
          'PNG',
          'jpg',
          'JPG',
          'jpeg',
          'JPEG',
          'webp',
          'WEBP',
          'svg',
          'SVG',
        ].includes(fileExtension)
      ) {
        data.append('type', 'document');
      } else {
        data.append('type', 'video');
      }

      data.append('file', activityData?.media?.profs[0]?.id ? 0 : file);
      //let result = await sendInitiativeProf(data);

      //if (result.response.status) {
        //getData();
      //}
      let token = localStorage.getItem('token');
      try {
        let result = await axios.post(getDomain() + '/api/trackingsystem/activities/add/prof', data , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: function(progressEvent:any) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            
            setProgressVal(percentCompleted)

          }
        });
        console.log('dsadsadafdata', result.data.data);

        if (result.data.status) {
          setShowProgressVal(false)
          setProgressVal(1)
          getData()
        }
      } catch (e) {
        dispatch(setLoading(false));
        showProgressVal(1)
        console.log(e);
      }

    }else{
      toast.error("Poof must be image or video");
    }
  };

  const addAttachFN = async (event: any) => {
    dispatch(setLoading(true));
    
   
    let data = new FormData();
    data.append('user_tracking_activity_id', activityData?.task_user_activity_id);
    
    activityData?.member_family &&
      data.append('family_member_id', activityData?.family_member_id);

   
      
      const file = event.target.files[0];
      console.log('file uru' , file )

      let x = {
        name: file?.name,
        uri: file?.uri,
        type: file?.type,
        size: file?.size,
      };

      data.append('attachment', file);

   
    
    let result = await sendAttach(data);
    if (result?.response?.status) {
      dispatch(setLoading(false));
      getData();
    }else{
      dispatch(setLoading(false));
    }
  };

  const removeAttachFN = async (event: any) => {
    dispatch(setLoading(true));
    
   
    let data = new FormData();
    data.append('user_tracking_activity_id', activityData?.task_user_activity_id);
    
    activityData?.member_family &&
      data.append('family_member_id', activityData?.family_member_id);

      data.append('attachment', '0');

   
    let result = await sendAttach(data);
    if (result?.response?.status) {
      dispatch(setLoading(false));
      getData();
    }else{
      dispatch(setLoading(false));
    }
  };

  const getAllNote = async () => {
    dispatch(setLoading(true)); 
    let data = new FormData();
    data.append('family_member_id', activityData?.family_member_id);
    data.append('activity_id', activityData?.id);
    let result = await getAllTaskNotes(data);
    if (result?.response?.status) {
      console.log('result?.response?.data', result?.response?.data);
        dispatch(setLoading(false));
        setAllNote(result.response.data)
        setShowAllNotes(true)
    }

  };

  const updateAuto = async (id: any, progress: any) => {
    dispatch(setLoading(true));
    let data = new FormData();
    data.append('user_activity_id', activityData.task_user_activity_id);
    data.append('progress', progress);
    data.append('media_id', id);
    activityData?.member_family &&
      data.append('family_member_id', activityData?.family_member_id);
    let result = await updateProgressAutomatic(data);

    if (result.response.status) {
      dispatch(setLoading(false));
      getData();
    }else{
      dispatch(setLoading(false)); 
    }
  };

  const removeProf = async () => {
    

    let data = new FormData();
    data.append('user_activity_id', activityData.task_user_activity_id);
    data.append('activity_id', String(id));

    data.append('prof', activityData?.media?.profs[0]?.id ?? 0);
    data.append('type', 'document');

    data.append('file', '0');
    let result = await sendInitiativeProf(data);

    if (result.response.status) {
      getData();
    }
  };

  const CheckDate = () => {
    return (
      moment(today).isSame(activityData?.task_start_date) ||
      moment(today).isSame(activityData?.task_end_date) ||
      moment(today).isBetween(
        activityData?.task_start_date,
        activityData?.task_end_date,
      )
    );
  };

  

  const deleteNote = async (noteId: any) => {
    let token = localStorage.getItem('token');
    try {
      let result = await axios.delete(
        `${getDomain()}/api/trackingsystem/note/${noteId}`,

        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (result.data.status) {
        setConfirmDeleteNote(false);
        setShowAllNotes(false)
        getData();
      }
    } catch (e) {}
  };

  const addNote = async (actId: any) => {
    let data = new FormData();
    data.append(`note`, selectedNoteValue);
    data.append(`user_tracking_activity_id`, activityData.task_user_activity_id);

    console.log(data);
    let result = await addTrackNote(data);

    if (result.response.status) {
      setShowAddNote(false);
      getData();
    }
  };

  const editNote = async () => {
    let data = new FormData();
    data.append('note', selectedNoteValue);
    data.append('id', selectedNoteId);
    let result = await editTrackNote(data);

    if (result.response.status) {
      setShow(false);
      setShowAllNotes(false)
      getData();
    }
  };

 
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={`mt-md-5 position-relative`}>
      <Toaster position="top-right" />
      <Container>
        <Row className="d-flex justify-content-between">
          <Col xs={12} lg={12} className="mt-3">
            <Card style={{
                      padding: '1rem 2rem' ,
                      borderColor: colors.darkGreen ,
                      marginBottom: '2rem',
                      background: activityData?.is_task_completed == 1 ? colors.darkGreen : colors.white }}>
              <div
                className=""
                >
                <div>
                  <img
                    src={activityData?.
                      consultant_avatar  ?? '/icons/manHolder.png'}
                    style={{
                      width: '50px',
                      height: '50px',
                      margin: '0 10px',
                      borderRadius: '50%',
                    }}
                  />
                  <span>{activityData?.consultant_name}</span>
                </div>  

                <h4 style={{margin: '10px'}}>{activityData?.name_en ?? activityData?.name_ar }</h4>

                <hr />

                <div
                className='d-flex justify-content-between'
                style={{margin: '20px 10px 10px 10px'}}
                >
                  <div>
                    
                    <div>
                      {moment(activityData?.task_start_date).locale('en').format('DD MMM YYYY')} - {moment(activityData?.task_end_date).locale('en').format('DD MMM YYYY')}
                    </div>
                  </div>

                  

                  { activityData?.member_family ?   
                  <div>
                    <span
                    style={{padding: '10px' , background: colors.selected , borderRadius: '15px' }}
                    >
                      {activityData?.member_family}
                    </span>
                  </div>
                  : '' }

                </div>
                
              </div>
            </Card>
          </Col>

          <div className="mb-2">
            <h4>About Task</h4>
            <p className={`text-font-muted ${ lang == 'ar' ? 'ar-dir' : '' }`}>{activityData?.long_desc_en}</p>
          </div>

          {activityData?.intro_video ? (
            <div className="mb-5" style={{ cursor: 'pointer' }}>
              <h4>{t('Intro Video')}</h4>
              <div className="media mt-3">
                <div className="d-flex justify-content-between flex-column mb-3">
                  <div>
                  <FaPlay
                          className="activity-icon"
                          style={{
                            color:colors.chamThirdColor ,
                            background:colors.lightred,
                            fontSize:'50px',
                            borderRadius: '10px',
                            padding: '15px',
                            
                          }}
                          />
                    
                  </div>

                  <video
                    width="320"
                    height="240"
                    controls
                    className="mt-4"
                    style={{borderRadius: '15px'}}>
                    <source
                      src={activityData?.intro_video}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {activityData?.task_media?.attachments?.length > 0 && (
            <div className="mb-5" style={{ cursor: 'pointer' }}>
              <h4>{t('Attachments')}</h4>
              <div className="attachments mt-3">
                {activityData?.task_media?.attachments.map((a: any) => {
                  return (
                    <div className="d-flex justify-content-between align-items-center mb-3"
                    
                    >
                      <div
                      onClick={() => {
                        if ( CheckDate() ) {
                          
                          window.open(a.media_path, '_blank');
                            updateAuto(a.id, 100);
                        } else {
                          toast.error("Can't open today");
                        }
                      }}
                      >
                      <FaRegFileAlt 
                        className="activity-icon"
                      style={{
                        color:colors.chamThirdColor ,
                        background:colors.lightred,
                        fontSize:'50px',
                        borderRadius: '10px',
                        padding: '10px',
                        
                      }}
                      />
                        <span>{a.name}</span>
                      </div>

                      <div>
                        <div className='d-flex align-items-center'>
                          {a.is_answered ? 
                            <FaRegCheckSquare
                              size={19}
                              color="green"
                              style={{
                                verticalAlign: 'text-bottom',
                                margin: '1.5rem',
                              }}
                            />
                            :
                            ''
                          }

                            <div
                              >
                              <img
                              onClick={ () =>  window.open(`${a.media_path}`,'_blank') }
                                src="/icons/download.png"
                                alt="image"
                                style={{
                                  width: '50px',
                                  margin: '0 20px 0 0',
                                }}
                              />

                                 
                            </div>

                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {activityData?.task_media?.videos.length != 0 && (
            <div className="mb-5" style={{ cursor: 'pointer' }}>
              <h4>{t('Media')}</h4>
              <div className="media mt-3">
                <div
                  className={`d-flex justify-content-between mb-3`}> 
                  <div>
                  <FaPlay
                      className="activity-icon"
                      style={{
                        color:activityData?.task_media?.videos[0].percentage != 0 ? colors.chamThirdColor : colors.blue ,
                        background:activityData?.task_media?.videos[0].percentage != 0 ? colors.lightred : colors.whiteBlue,
                        fontSize:'50px',
                        borderRadius: '10px',
                        padding: '15px',
                        
                      }}
                      />
                    <span>{activityData?.task_media?.videos[0].name}</span>
                  </div>

                  <div className='d-flex align-items-center' >

                  

                  {activityData?.task_media?.videos[0].is_answered ? 
                    <FaRegCheckSquare
                      size={19}
                      color="green"
                      style={{
                        verticalAlign: 'text-bottom',
                        margin: '1.5rem',
                      }}
                    />
                  :
                  ''
                  }

                {activityData?.task_media?.videos[0].percentage == 0 && ( <ZeroProgressPopover /> ) }

                </div>

                  
                    
                    
                  
                </div>

               
                  { activityData?.task_media?.videos.length && (
                  <video
                  width="320"
                  height="240"
                  controls
                  className="mt-4"
                  style={{borderRadius: '15px'}}
                  onEnded={ () => { updateAuto(activityData?.task_media?.videos[0].id,100) }} 
                  >
                  <source
                    src={activityData?.task_media?.videos[0].media_path}
                    type="video/mp4"
                  />
                </video>
                ) }
                
              </div>
            </div>
          )}

          {activityData?.task_media?.quizs?.data && (
            <div className="mb-5" style={{ cursor: 'pointer' }}>
              <h4>{t('Quiz')}</h4>
              <div className="quiz mt-3">
                <div
                  className="d-flex justify-content-between align-items-center mb-3"
                  >
                  <div className="d-flex justify-content-between align-items-center"
                  onClick={() => {
                    if( CheckDate() ){

                      if (activityData?.task_media?.quizs?.is_answered !== 1){

                        navigate(`/tracking-system-quiz`, {
                          state: {
                            questions: activityData?.task_media?.quizs.data,
                            mediaId: activityData?.task_media?.quizs.id,
                            userActivityId:
                              activityData?.task_user_activity_id,
                              
                            percentage: activityData?.task_media?.quizs.percentage,  
                          },
                        });
                      }else{
                        toast.error('Already Answered');
                      }
                      

                    }else{
                      toast.error("you can view this activity only when it starts");
                    }
                    
                  }}
                  >
                    
                    <FaRegEdit 
                      className="activity-icon"
                    style={{
                      color: colors.maincolor ,
                      background: colors.lightred ,
                      fontSize:'50px',
                      borderRadius: '10px',
                      padding: '10px',
                      
                    }}
                    />
                    <div>
                      <span>{t('Quiz Title')}</span>
                      <p className="m-0" style={{color: '#b3b3b3'}}>
                        {t('Quiz')},{' '}
                        {activityData?.task_media?.quizs.data.questions.length}{' '}
                        {t('Questions')}
                      </p>
                    </div>
                  </div>

                  <div className='d-flex align-items-center'>
                  {activityData?.task_media?.quizs?.is_answered == 1 ? (
                    <FaRegCheckSquare
                      size={19}
                      color="green"
                      style={{
                        verticalAlign: 'text-bottom',
                        margin: '1.5rem',
                      }}
                    />
                  ) : (
                    ''
                  )}

                 

                  </div>
                  

                  
                </div>
              </div>
            </div>
          )}

          {activityData?.prof != 0 && (
          <div className="mb-5" style={{ cursor: 'pointer' , position: 'relative' }}>
           
           {
                     !CheckDate()
                     
                     ?
                 
                  <div
                    style={{ 
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top:'0',
                      left:'0',
                      zIndex: '2',
                  }}
                  onClick={ () => {
                    toast.error("Can't open today");
                  } }
                  >
                  </div>
                  :
                  ''
                   }
           
            <div className='d-flex justify-content-between align-items-center'>
              <h4>{t('Add Proof')}</h4>
            </div>

            <div className="proof text-center">
              { activityData?.task_media?.profs.length == 0 && (
                <div>
                  <div
                    className={`${practicalTaskClasses.UploadBtnWrapper} w-100 mb-4 mt-3`}
                  
                    >
                    <button className="w-100 p-4">
                    
                        <img src="/practical-task/clip.png" />
                      <div
                        style={{
                          color: '#0576e3',
                          marginTop: '1rem',
                          fontWeight: 'normal',
                        }}>
                        <strong style={{textDecoration: 'underline'}}>
                          {t('Click')}
                        </strong>{' '}
                        {t('to attach your file')}
                      </div>
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      className={practicalTaskClasses.UploadBtnWrapper}
                      onChange={handleFileChange}
                      id="pictureX"
                     
                    />
                  </div>
                  { showProgressVal && (<ProgressBar now={progressVal} label={`${progressVal}%`} />)}
                </div>
              )}
              
              { activityData?.media?.profs.length  ? (
                <div
                  className={`d-flex justify-content-between align-items-center`}>
                    <div className='d-flex align-items-center'>
                    <FaPaperclip
                    className="activity-icon"
                      style={{
                        color:colors.maincolor ,
                        background:colors.lightred,
                        fontSize:'50px',
                        borderRadius: '10px',
                        padding: '12px',
                        
                      }}
                      />
                  <div>{t('Proof Added')}</div>  
                  {
                  profMidea ? 
                  <div
                    onClick={() => {
                      window.open(activityData?.task_media?.profs[0].media_path, '_blank');
                    }}>
                    <img
                      src="/icons/open-eye.png"
                      alt="image"
                      style={{
                        margin:'0 10px',
                        cursor:'pointer'
                      }}
                    />
                  </div>
                  : ''
                }
                </div>

           
                <div className='d-flex align-items-center'>  

                {activityData?.media?.profs.length &&
              activityData?.media?.profs[0].status != 1 ? (
                  <div
                    onClick={() => {
                      
                      removeProf()
                      setprofMidea(null);
                      setSelectedFile(null);
                    }}>
                    <img
                      alt="img"
                      src="/practical-task/close.png"
                      style={{width: '20px',margin:'10px'}}
                      
                    />
                  </div>

                  ) : (
                    ''
                  )}
                      
              {activityData?.media?.profs.length 
             ? (
                    <FaRegCheckSquare
                      size={19}
                      color="green"
                      style={{
                        verticalAlign: 'text-bottom',
                        margin: '1.5rem 0',
                      }}
                    />
                  ) : (
                    ''
                  )}

                

                  </div>

                </div>

              )
            :
            ''
            }

              
            </div>
            

          </div>
          )}

         
         {/*upload attachment*/}
          <div className="mb-5" style={{ cursor: 'pointer' , position: 'relative' }}>
           
          {
                     !CheckDate()
                     
                     ?
                 
                  <div
                    style={{ 
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top:'0',
                      left:'0',
                      zIndex: '2',
                  }}
                  onClick={ () => {
                    toast.error("Can't open today");
                  } }
                  >
                  </div>
                  :
                  ''
                   }
           
            <div className='d-flex justify-content-between align-items-center'>
              <h4>{t('Upload Attachments')}</h4>
            </div>

            <div className="proof text-center">
              { activityData?.user_attachment_path == null && (
                <div>
                  <div
                    className={`${practicalTaskClasses.UploadBtnWrapper} w-100 mb-4 mt-3`}
                  
                    >
                    <button className="w-100 p-4">
                    
                        <img src="/practical-task/clip.png" />
                      <div
                        style={{
                          color: '#0576e3',
                          marginTop: '1rem',
                          fontWeight: 'normal',
                        }}>
                        <strong style={{textDecoration: 'underline'}}>
                          {t('Click')}
                        </strong>{' '}
                        {t('to attach your file')}
                      </div>
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      className={practicalTaskClasses.UploadBtnWrapper}
                      onChange={addAttachFN}
                      id="pictureX"
                     
                    />
                  </div>
                 
                </div>
              )}
              
              { activityData?.user_attachment_path  ? (
                <div
                  className={`d-flex justify-content-between align-items-center`}>
                    <div className='d-flex align-items-center'>
                    
                  
                  {
                  
                  <div
                    onClick={() => {
                      window.open(activityData?.user_attachment_path, '_blank');
                    }}>
                    <img
                      src="/icons/attach.png"
                      alt="image"
                      style={{
                        margin:'0 10px',
                        cursor:'pointer',
                        width:'60px',
                      }}
                    />
                  </div>
                  
                }
                </div>

           
                <div className='d-flex align-items-center'>  

                {activityData?.user_attachment_path ? (
                  <div
                    onClick={removeAttachFN}>
                    <img
                      alt="img"
                      src="/practical-task/close.png"
                      style={{width: '20px',margin:'10px'}}
                      width={60}
                    />
                  </div>

                  ) : (
                    ''
                  )}
                      
              {activityData?.user_attachment_path 
             ? (
                    <FaRegCheckSquare
                      size={19}
                      color="green"
                      style={{
                        verticalAlign: 'text-bottom',
                        margin: '1.5rem 0',
                      }}
                    />
                  ) : (
                    ''
                  )}

                

                  </div>

                </div>

              )
            :
            ''
            }

              
            </div>
            

          </div>
          {/*upload attachment*/}
        

          {/*notes*/}  
          <div className="mb-5">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="m-0">{t('Notes')}</h4>
              <div
                
                style={{
                  color: '#0576e3',
                  fontWeight: '500',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                  
                }}
                onClick={() => getAllNote()}>
                {t('See all')}
              </div>
            </div>

            {activityData?.notes?.length > 0 && (
              <div>
                {activityData?.notes.map((n: any) => {
                  return (
                    <div className="media mt-3">
                      <div className="d-flex align-items-center mb-3">
                        <img
                          src="/icons/file.png"
                          alt="image"
                          style={{width: '50px', margin: '0 20px 0 0'}}
                        />
                        <div className="w-100">
                          <span>{n.note}</span>
                          <div className="d-flex justify-content-between align-items-center">
                            <p
                              className="m-0 w-100"
                              style={{color: '#b3b3b3'}}>
                              {moment(n.updated_at)
                                .locale('en')
                                .format('D MMM YYYY')}
                            </p>
                            <div className="d-flex align-items-end">
                              <p
                                className="cursor-pointer"
                                style={{color: '#0acad7', margin: '0 20px'}}
                                onClick={() => {
                                  setShow(true);
                                  setSelectedNoteIdValue(n.note);
                                  setSelectedNoteId(n.id);
                                }}>
                                Edit
                              </p>
                              <p
                                className="m-0 secondry-color cursor-pointer"
                                onClick={() => {
                                  setConfirmDeleteNote(true);
                                  setSelectedNoteId(n.id);
                                }}>
                                Delete
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <div>
              <p
                className="cursor-pointer secondry-color mt-4"
                style={{fontSize: '18px'}}
                onClick={() => {
                  setShowAddNote(true);
                }}>
                + {t('Add new note')}
              </p>
            </div>
          </div>
          {/*notes*/}

          {/*expert feedback*/}
         
          <div className="mb-5" style={{ cursor: 'pointer' }}>
            <h4>{t('Expert Feedback')}</h4>
            <div className="attachments mt-3">
              { activityData?.user_activity_comments.map((a: any) => {
                return (
                  <div>
                    <div>
                    {a.comment && (
                      <textarea disabled rows={5}  className='w-100'
                      style={{
                        padding: '10px',
                        borderRadius: '15px',
                        margin :'10px 0'
                      }}
                      >
                        {a.comment}
                      </textarea>
                    ) }
                    </div>
                    <div>
                      { a.reply_attachment && (
                      <div className="d-flex justify-content-between align-items-center mb-3"
                      onClick={ () =>  window.open(`${a.reply_attachment}`,'_blank') }
                      >
                        <div
                      
                        >
                        <FaRegFileAlt 
                          className="activity-icon"
                        style={{
                          color:colors.maincolor ,
                          background:colors.lightred,
                          fontSize:'50px',
                          borderRadius: '10px',
                          padding: '10px',
                          
                        }}
                        />
                          <span>{a.name}</span>
                        </div>

                        <div>
                          <div className='d-flex align-items-center'>
                            

                              <div
                                >
                                <img
                               
                                  src="/icons/download.png"
                                  alt="image"
                                  style={{
                                    width: '50px',
                                    margin: '0 20px 0 0',
                                  }}
                                />

                                    
                              </div>

                          </div>
                        </div>
                      </div>
                      ) }
                    </div>
                  </div>

                );
              })}
            </div>
            {
              activityData?.user_activity_comments.length == 0 ? 
              <h3 className='secondry-color text-center'>No Feedback Yet</h3>
              :
              ''
            }
          </div>
         
          {/*expert feedback*/}



        </Row>
      </Container>

      {/*Edit Note*/}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="">
            <h5>Edit Note</h5>
            <textarea
              className={`${classes.Textarea} w-100 mb-1 mt-3`}
              rows={5}
              onChange={e => {
                setSelectedNoteIdValue(e.target.value);
              }}>
              {selectedNoteValue}
            </textarea>
            <span className="">Maximum number of characters is 150</span>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => editNote()}>
            <MainButton text={'Edit note'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Add Note*/}
      <Modal show={showAddNote} onHide={() => setShowAddNote(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="">
            <h5>Add Note</h5>
            <textarea
              className={`${classes.Textarea} w-100 mb-1 mt-3`}
              rows={5}
              onChange={e => {
                setSelectedNoteIdValue(e.target.value);
              }}></textarea>
            <span className="">Maximum number of characters is 150</span>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div
            className="w-100"
            onClick={() =>
              selectedNoteValue && addNote(activityData.task_user_activity_id)
            }>
            <MainButton text={'Add note'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Confirm delete note*/}
      <Modal
        show={confirmDeleteNote}
        onHide={() => setConfirmDeleteNote(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">
              Are you sure that you want to delete the selected note ?
            </h3>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => deleteNote(selectedNoteId)}>
            <MainButton text={'Confirm'} />
          </div>
          <div onClick={() => setConfirmDeleteNote(false)} className="w-100">
            <CancelButton text={'Cancel'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Note List*/}
      <Modal show={showAllNotes} onHide={() => setShowAllNotes(false)} centered>
        

        <Modal.Body>
        {allNote.length > 0 && (
          <div>
            {allNote.map((n: any) => {
              return (
                <div className="media mt-3">
                  <div className="d-flex align-items-center mb-3">
                    <img
                      src="/icons/file.png"
                      alt="image"
                      style={{width: '50px', margin: '0 20px 0 0'}}
                    />
                    <div className="w-100">
                      <span>{n.note}</span>
                      <div className="d-flex justify-content-between align-items-center">
                        <p
                          className="m-0 w-100"
                          style={{color: '#b3b3b3'}}>
                          {moment(n.note_added_date)
                            .locale('en')
                            .format('D MMM YYYY')}
                        </p>
                        <div className="d-flex align-items-end">
                          <p
                            className="cursor-pointer"
                            style={{color: '#0acad7', margin: '0 20px'}}
                            onClick={() => {
                              setShow(true);
                              setSelectedNoteIdValue(n.note);
                              setSelectedNoteId(n.id);
                            }}>
                            Edit
                          </p>
                          <p
                            className="m-0 secondry-color cursor-pointer"
                            onClick={() => {
                              setConfirmDeleteNote(true);
                              setSelectedNoteId(n.id);
                            }}>
                            Delete
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
         {allNote.length == 0 && (
          <h5 className='text-center'>There is no data found...</h5>
         )}
        </Modal.Body>

       
      </Modal>

    </div>
  );
};

export default UserTaskAddProgress;
