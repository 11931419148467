import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calender.css';
import {CalendarProp} from './ICalendar';

const CalenderContainer = ({date, onChange}: CalendarProp) => {
  return (
    <div>
      <Calendar onChange={onChange} value={date} />
    </div>
  );
};

export default CalenderContainer;
