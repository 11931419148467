import consultantImg from '../style/consultant.jpg';
import '../style/ConsultantCard.css';
import {useNavigate} from 'react-router-dom';
import {ConsultantItemProps} from '../interfaces/IConsultants';
import classes from '../style/ConsultantCardHorizontal.module.css';
import {truncate} from '../../../helpers/truncate';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function ConsultantCardHorizontal({consultant}: ConsultantItemProps) {

  const {t} = useTranslation();

  const navigate = useNavigate();

  function handleClick() {
    navigate(
      `/consultants/${consultant?.consultation_info_type}/${consultant?.id}`,
    );
  }
  const fields = consultant?.consultation_info_fields.slice(0, 2);
  
  return (
    <div>
      <Card className="list-item-card mentor consultant-card position-relative" onClick={handleClick}>
      <p className='top-card-label'>
        {consultant?.first_session_free == 1 && (
        <span className='price-label'>
        {t('First Lesson Free')}
        </span>
        )}

        {/* 
        <span className='type-label'>
          {consultant?.consultation_info_type == 'organization'
            ? 'mentor'
            : consultant?.consultation_info_type}
        
        </span>
        */}

      </p>
      <Card.Img
        variant="top"
        src={consultant?.avatar ?? consultantImg}
        className="ConsAvatar"
      />
      <Card.Body className="mt-3 mb-1">
        <Card.Title className="card-custom-title card-title h5">
          {consultant?.username}
        </Card.Title>

        { consultant?.available_sessions 
        
          ?

          <p className="availabitily-1 m-0">
          {consultant?.available_sessions} {t('available')}
          </p>

          :

          <p className="availabitily-0">
          No Sessions Available
          </p>

        }

        <Card.Title>
          {/*
          {fields?.map((l, i) => (
            <span style={{fontSize: '17px'}} className="card-custom-specialize" key={i}>
              {l.title} {i + 1 < fields?.length ? ' . ' : ''}
            </span>
          ))}
            */}

        </Card.Title>

        <div className="cons-langs d-flex mb-2 align-items-center gap-2">
          
            {consultant?.consultation_info_languages?.slice(0, 2).map((l, i) => (
              <div className="lang-feature">
                <img src="/tabler_language.svg" /> <span key={i}>{l.name}</span> 
              </div>
              
            ))}

            {consultant?.consultation_info_languages && consultant?.consultation_info_languages?.length > 2 && (
              <p className='m-0'>+{consultant?.consultation_info_languages?.length - 2}</p>
            )}
            
          
        </div>

        

        

        {/*
        <Card.Text className="mb-2 consultant-rate">
          <RateStars rateValue={consultant?.ratings} />
        </Card.Text>
        */}
       
      </Card.Body>
    </Card>
      <div className={`${classes.Item} d-none`} onClick={handleClick}>
        <span
          className={`course-type ${
            consultant?.consultation_info_type == 'coach'
              ? 'live-course-type'
              : 'recorded-course-type'
          }`}>
          {consultant?.consultation_info_type == 'organization'
            ? 'coach'
            : consultant?.consultation_info_type}
        </span>
        <h5 className="mt-2 mb-4">{consultant?.username}</h5>
        <div className="d-flex ">
          <div className={`${classes.Image}`}>
            <img src={consultant?.avatar ?? consultantImg} />
          </div>

          <div style={{flexGrow: '1', padding: '0 1rem'}}>
            <div>
              <h5 className="secondry-color mb-3" style={{fontSize: '17px'}}>
                {fields?.map((l, i) => (
                  <span key={i}>
                    {l.title} {i + 1 < fields?.length ? ' . ' : ''}
                  </span>
                ))}
              </h5>
            </div>
            <div
              className={`${classes.Languages} d-flex mb-1 align-items-center`}>
              {consultant?.consultation_info_languages &&
                consultant?.consultation_info_languages.length > 0 && (
                  <div>
                    {consultant?.consultation_info_languages
                      .slice(0, 2)
                      .map((l, i) => (
                        <span key={i}>{l.name}</span>
                      ))}
                  </div>
                )}
              {consultant?.consultation_info_languages &&
                consultant?.consultation_info_languages.length > 2 && (
                  <p>+{consultant?.consultation_info_languages.length - 2}</p>
                )}
            </div>
            <div className="mb-1">
              <span className={`${classes.Availabilty} mt-2`}>
                {consultant?.available_sessions} available
              </span>
            </div>
            {consultant?.first_session_free != 0 && (
              <p>
                <span className={`${classes.Availabilty} mt-2`}>
                  First Lesson Free
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultantCardHorizontal;
