import { urlParamReplace } from '../../helpers/UrlParamReplace';
import {APIURL} from '../../services/http/api';
import { EApiParams } from '../../services/http/apiParams.enum';
import {HttpService} from '../../services/http/http.service';

const http = new HttpService();

export const SubmitSurvey = async (data: FormData) => {
  const api = APIURL.survey.submit;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};
export const getSurvey = async (id:number) => {
  const api = APIURL.experts.getSurvey;
  const finalApi = urlParamReplace(api, {[EApiParams.id]: id});
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};

export const getUserSurveyList = async () => {
  const api = APIURL.experts.getUserSurveyList;
  const [err, resp] = await http.get(api);

  return {error: err, response: resp?.data};
};
