import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useEffect, useState} from 'react';
import CoursesList from '../../Courses/CoursesList';
import {getCoursesList} from '../../Courses/Courses.service';
import {courseProvider} from '../../Courses/interfaces/ICourses';
import {Enums} from '../../../helpers/Enums';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const MyCourses = () => {
  const [page, setPage] = useState<number>(1);
  const [title, setTitle] = useState<string>('');
  const courseProvider: courseProvider[] = [
    {id: 0, name: Enums.Nurabi},
    {id: 1, name: Enums.Consultants},
    {id: 2, name: Enums.teachers},
    {id: 3, name: Enums.Coaches},
  ];
  const [learnType, setLearnType] = useState<string[]>([]);
  const [categoriesSelected, setCategoriesSelected] = useState<number[]>([]);
  const [languagesSelected, setLanguagesSelected] = useState<number[]>([]);
  const [courseProviderSelected, setCourseProviderSelected] = useState<
    number[]
  >([]);
  const [pricing, setPricing] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState('new');

  useEffect(() => {
    getCourses();
  }, [
    learnType,
    categoriesSelected,
    languagesSelected,
    pricing,
    courseProviderSelected,
    page,
    title,
    activeTab,
  ]);

  const getCourses = () => {
    getCoursesList({
      page: page,
      load: false,
      learnType: learnType,
      title: title,
      language: languagesSelected,
      categories: categoriesSelected,
      courseProvider: courseProviderSelected,
      price: pricing,
      status: activeTab,
    });
  };

  const handleSelect = (key: string | null) => {
    if (key != null) {
      setActiveTab(key);
    }
  };

  return (
    <div>
      <Tabs
        defaultActiveKey="new"
        className="custom-web-tabs mb-2"
        onSelect={(key: string | null) => handleSelect(key)}>
        <Tab eventKey="new" title="New"></Tab>
        <Tab eventKey="in_progress" title="In Progress"></Tab>
        <Tab eventKey="completed" title="Completed"></Tab>
      </Tabs>

      <div className="content-details my-courses-list">
        <Container>
          <Row className="d-flex justify-content-between">
            <Col xs={12}>
              <CoursesList
                setPage={setPage}
                page={page}
                submitTitle={setTitle}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default MyCourses;
