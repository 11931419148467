import './CustomRadio.css';
const CustomRadio = ({text, checked, ...res}) => {
  return (
    <div className="d-flex justify-content-between" {...res}>
      <label htmlFor="test" className="radio-option-label">
        <input type="radio" name="groupName" checked={checked} />
        <div className="radio-option">{text}</div>
      </label>
    </div>
  );
};

export default CustomRadio;
