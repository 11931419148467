import {ComponentsProps} from '../interface/ISurvey';
import classes from '../style/Survey.module.css';

const MultiSelect = ({allData, index, options, selected}: ComponentsProps) => {
  return options?.map((i, key) => {
    const checkedStatus = () => {
      const answer = allData?.[index!].answer;
      if (Array.isArray(answer)) {
        return answer.includes(i.id);
      }
    };
    return (
      <p className={classes.Question}>
        <input
          type="checkbox"
          id={`answer-${i.id}-${index}`}
          name="radio-group-1"
          onChange={() => {
            selected(i.id);
          }}
          checked={checkedStatus()}
        />
        <label htmlFor={`answer-${i.id}-${index}`}>{i.option}</label>
      </p>
    );
  });
};

export default MultiSelect;
