import {Col, Container, Row} from 'react-bootstrap';
import MainButton from '../../../UI/main-button/MainButton';
import classes from '../style/AddAvailability.module.css';
import {useEffect, useState} from 'react';
import CustomSelectRadio from '../../../UI/custom-select-radio/CustomSelectRadio';
import {getPrices, sendBlocks} from '../Availability.service';
import {useAppDispatch} from '../../../hooks/hooks';
import {setLoading} from '../../../store/userSlice';
import moment from 'moment';
import {useLocation, useNavigate} from 'react-router-dom';
const AddSingleSession = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const daySelected = location.state.daySelected;
  const [startTime, setStartTime] = useState('');
  const [availabiltyDurationType, setavailabiltyDurationType] = useState<
    string | null
  >(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [duration30, setDuration30] = useState(false);
  const [duration60, setDuration60] = useState(false);
  const [duration90, setDuration90] = useState(false);
  const [duration120, setDuration120] = useState(false);
  const [duration150, setDuration150] = useState(false);
  const [duration180, setDuration180] = useState(false);

  useEffect(() => {
    dispatch(setLoading(true));
    ReadData();
  }, []);

  const checkIfStart = () => {
    const start = moment(
      `${daySelected} ${startTime}`,
      'YYYY-MM-DD HH:mm:ss',
    ).locale('en');
    const now = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
    return start.isSameOrAfter(now);
  };
  const checkStillSameDay = () => {
    const start = moment(
      `${daySelected} ${startTime}`,
      'YYYY-MM-DD HH:mm:ss',
    ).locale('en');

    return (
      moment(daySelected, 'YYYY-MM-DD').isSame(
        moment(start).add(availabiltyDurationType, 'minutes').locale('en'),
        'day',
      ) ||
      moment(start)
        .add(availabiltyDurationType, 'minutes')
        .locale('en')
        .format('HH:mm:ss') == '00:00:00'
    );
  };

  const handleButtonClick = async () => {
    if (!startTime) {
      setErrorMessage('set Start');
    } else if (availabiltyDurationType == null) {
      setErrorMessage('set Period');
    } else if (!checkIfStart()) {
      setErrorMessage('start Cannot be for Now');
    } else if (!checkStillSameDay()) {
      setErrorMessage('delete Relate Period');
    } else {
      let data = new FormData();
      data.append(
        'from',
        moment(startTime, 'HH:mm').locale('en').format('HH:mm:ss'),
      );
      data.append(
        'to',
        moment(startTime, 'HH:mm')
          .add(availabiltyDurationType, 'minutes')
          .locale('en')
          .format('HH:mm:ss') == '00:00:00'
          ? '24:00:00'
          : moment(startTime, 'HH:mm')
              .add(availabiltyDurationType, 'minutes')
              .locale('en')
              .format('HH:mm:ss'),
      );
      data.append('duration', availabiltyDurationType);
      data.append(
        `days[0]`,
        moment(daySelected).locale('en').format('DD-MM-YYYY'),
      );

      let result = await sendBlocks(data);
      if (result.response.status) {
        navigate(-1);
      } else {
        setErrorMessage(
          "There are some lessons that we can't add as their times intersected with previously added lessons",
        );
      }
    }
  };

  const ReadData = async () => {
    try {
      let result = await getPrices();
      if (result.response.status && result.response.data) {
        if (
          result?.response?.data?.duration30 &&
          result?.response?.data?.duration30 !== 0
        ) {
          setDuration30(true);
        }
        if (
          result?.response?.data?.duration60 &&
          result?.response?.data?.duration60 !== 0
        ) {
          setDuration60(true);
        }
        if (
          result?.response?.data?.duration90 &&
          result?.response?.data?.duration90 !== 0
        ) {
          setDuration90(true);
        }
        if (
          result?.response?.data?.duration120 &&
          result?.response?.data?.duration120 !== 0
        ) {
          setDuration120(true);
        }
        if (
          result?.response?.data?.duration150 &&
          result?.response?.data?.duration150 !== 0
        ) {
          setDuration150(true);
        }
        if (
          result?.response?.data?.duration180 &&
          result?.response?.data?.duration180 !== 0
        ) {
          setDuration180(true);
        }
        dispatch(setLoading(false));
      }
    } catch (e) {
      console.log({e});
    }
  };
  function generateTimeIntervals() {
    const intervals = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        intervals.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return intervals;
  }
  const timeIntervals = generateTimeIntervals();
  return (
    <div className="mt-md-5">
      <Container className={classes.FormContainer}>
        <h3 className="w-100 mb-4 mt-3 font-weight-bold text-center cursor-pointer">
          Add Single Lesson
        </h3>
        <Row className="d-flex justify-content-between">
          <div className={classes.FormPadding}>
            <Row>
              <Col md={{span: 6, offset: 3}}>
                <div className="mt-3 mb-3">
                  <label className="mb-2">
                    <span style={{color: 'red'}}>* </span>Start Time
                  </label>
                  <select
                    value={startTime}
                    className={`form-control m-0 ${classes.FormInput}`}
                    onChange={e => setStartTime(e.target.value)}>
                    <option value="" disabled>
                      Select a time
                    </option>
                    {timeIntervals.map(interval => (
                      <option key={interval} value={interval}>
                        {moment(interval, 'HH:mm').format('hh:mm A')}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>

              <Col md={{span: 6, offset: 3}}>
                <h6 className="mt-2">Lesson Duration</h6>
                <div className="mt-3 mb-3">
                  <Row>
                    {duration30 && (
                      <Col xs={12}>
                        <CustomSelectRadio
                          name="periods-items"
                          text="30 Minutes"
                          forId={'30'}
                          disabled={false}
                          value={'30'}
                          onRadioChange={(value: string) =>
                            setavailabiltyDurationType(value)
                          }
                          checked={availabiltyDurationType == '30'}
                        />
                      </Col>
                    )}
                    {duration60 && (
                      <Col xs={12}>
                        <CustomSelectRadio
                          name="periods-items"
                          text="60 Minutes"
                          forId={'60'}
                          disabled={false}
                          value={'60'}
                          onRadioChange={(value: string) =>
                            setavailabiltyDurationType(value)
                          }
                          checked={availabiltyDurationType == '60'}
                        />
                      </Col>
                    )}
                    {duration90 && (
                      <Col xs={12}>
                        <CustomSelectRadio
                          name="periods-items"
                          text="90 Minutes"
                          forId={'90'}
                          disabled={false}
                          value={'90'}
                          onRadioChange={(value: string) =>
                            setavailabiltyDurationType(value)
                          }
                          checked={availabiltyDurationType == '90'}
                        />
                      </Col>
                    )}
                    {duration120 && (
                      <Col xs={12}>
                        <CustomSelectRadio
                          name="periods-items"
                          text="120 Minutes"
                          forId={'120'}
                          disabled={false}
                          value={'120'}
                          onRadioChange={(value: string) =>
                            setavailabiltyDurationType(value)
                          }
                          checked={availabiltyDurationType == '120'}
                        />
                      </Col>
                    )}
                    {duration150 && (
                      <Col xs={12}>
                        <CustomSelectRadio
                          name="periods-items"
                          text="150 Minutes"
                          forId={'150'}
                          disabled={false}
                          value={'150'}
                          onRadioChange={(value: string) =>
                            setavailabiltyDurationType(value)
                          }
                          checked={availabiltyDurationType == '150'}
                        />
                      </Col>
                    )}
                    {duration180 && (
                      <Col xs={12}>
                        <CustomSelectRadio
                          name="periods-items"
                          text="180 Minutes"
                          forId={'180'}
                          disabled={false}
                          value={'180'}
                          onRadioChange={(value: string) =>
                            setavailabiltyDurationType(value)
                          }
                          checked={availabiltyDurationType == '180'}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={{span: 6, offset: 3}}>
                <div className="mt-4 mb-2 danger-title">{errorMessage}</div>
              </Col>

              <Col md={{span: 6, offset: 3}}>
                <div className="mt-4 mb-2" onClick={handleButtonClick}>
                  <MainButton text="Confirm" />
                </div>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default AddSingleSession;
