import Form from 'react-bootstrap/Form';
import {CheckBoxProps} from './ICheckBoxTitle';

export const CheckBoxTitle = ({
  id,
  title,
  checked,
  setChecked,
}: CheckBoxProps) => {
  return (
    <Form.Check type="checkbox" id={id}>
      <Form.Check.Input
        type="checkbox"
        isValid
        checked={checked}
        onChange={i => {
          setChecked();
          window.scrollTo(0, 0);
        }}
      />
      <Form.Check.Label>{title}</Form.Check.Label>
    </Form.Check>
  );
};
