import {Container} from 'react-bootstrap';
import './style/PaymentResult.css';
import MainButton from '../../UI/main-button/MainButton';
import {useNavigate} from 'react-router';
import {getToCart} from '../../store/CartSlice';
import {useAppDispatch} from '../../hooks/hooks';
import {useEffect} from 'react';

const PaymentResult = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const paramValue: any = searchParams.get('payment_success');

  useEffect(() => {
    dispatch(getToCart());
  }, []);

  return (
    <Container fluid className="containerPayment">
      <section className="sectionPayment">
        {paramValue == 1 ? (
          <div>
            <img className="imgPayment" src="/icons/success.png" />
            <h2 className="titleStatus">Thank You !</h2>
            <h4 className="mb-3">Transaction Payment Successful</h4>
          </div>
        ) : (
          <div>
            <img className="imgFailedPayment" src="/icons/fail.jpg" />
            <h2 className="titleStatus">Sorry !</h2>
            <h4 className="mb-3">Transaction Payment Failed</h4>
          </div>
        )}
        <div
          style={{width: 'fit-content', margin: 'auto'}}
          onClick={() => {
            window.location.href = '/'
          }}>
          <MainButton text={'Back To Home'}></MainButton>
        </div>
      </section>
    </Container>
  );
};

export default PaymentResult;
