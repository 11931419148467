import './MainButton.css';
import Button from 'react-bootstrap/Button';
function MainButton({text, ...res}) {
  return (
    <Button className="card-button" variant="primary" {...res}>
      {text}
    </Button>
  );
}

export default MainButton;
