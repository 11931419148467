import {Col, Container, Row} from 'react-bootstrap';
import {useNavigate, useLocation} from 'react-router-dom';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/ResetPassword.module.css';
import {useState} from 'react';
import {ForgetPassAccount, ResetPassAccount} from './Auth.service';
import {FaEye} from 'react-icons/fa';

const ResetPassword = () => {
  const Location = useLocation();
  const navigate = useNavigate();
  const email: string = Location.state.email;
  const [password, setPassword] = useState<string>('');
  const [confPassword, setConfPassword] = useState<string>('');
  const [errorPass, setErrorPass] = useState<string>('');
  const [showPass, setShowPass] = useState<boolean>(false);
  const [showConfPass, setConfShowPass] = useState<boolean>(false);

  const Check = async () => {
    if (password == '' || confPassword == '') {
      setErrorPass('Wrong Data');
    } else if (password !== confPassword) {
      setErrorPass('Password not match confirm password');
    } else {
      let data: FormData = new FormData();
      data.append('email', email);
      data.append('new_password', password);
      data.append('lang', 'en');

      let result = await ResetPassAccount(data);
      console.log('result.response', result.response);
      if (result.response.status) {
        navigate('/login');
      }
    }
  };

  return (
    <div>
      <Container className={classes.FormContainer}>
        <Row className="d-flex justify-content-between">
          <Col lg={6}>
            <div className={classes.FormPadding}>
              <h3 className={`fontWeight-bold ${classes.HeaderTitle} `}>
                Reset Password
              </h3>
              <p className={`${classes.HeaderPargraph}`}>
                Enter Your Email Address To Confirm
              </p>

              <div className="mt-3 mb-3">
                <label className="mb-2">New Password</label>
                <div className="mt-3 mb-3" style={{position: 'relative'}}>
                  <div
                    className={classes.togglePassword}
                    onClick={() => setShowPass(!showPass)}>
                    {!showPass ? (
                      <img src="/icons/close.png" alt="img" width={25} />
                    ) : (
                      <FaEye color="#0576e3" size={20} />
                    )}
                  </div>
                  <input
                    type={showPass ? 'text' : 'password'}
                    className={`form-control m-0 ${classes.PasswordInput}`}
                    placeholder="Password"
                    required
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-3 mb-3" style={{position: 'relative'}}>
                <div
                  className={classes.togglePassword}
                  onClick={() => setConfShowPass(!showConfPass)}>
                  {!showConfPass ? (
                    <img src="/icons/close.png" alt="img" width={25} />
                  ) : (
                    <FaEye color="#0576e3" size={20} />
                  )}
                </div>
                <input
                  type={showConfPass ? 'text' : 'password'}
                  className={`form-control m-0 ${classes.PasswordInput}`}
                  placeholder="Password"
                  required
                  onChange={e => setConfPassword(e.target.value)}
                />
              </div>
              <div className={classes.error}>{errorPass}</div>

              <div className="mt-4 mb-2">
                <MainButton text="Confirm" onClick={Check} />
              </div>
            </div>
          </Col>

          <Col lg={6}>
            <img
              src="/login.png"
              alt="img"
              className={classes.LoginBackground}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResetPassword;
