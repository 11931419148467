import avatarImg from '../style/profile.png';
import '../style/Comments.css';
import RateStars from '../../../UI/rate-stars/RateStars';
import {Container} from 'react-bootstrap';
import {FeedBackProp} from '../interfaces/ICourses';

const CourseComments = ({comment}: FeedBackProp) => {
  return (
    
      <div className="comment-item d-flex mt-5 justify-content-between">
        <div className="d-flex align-items-center">
          <div className="comment-image d-flex justify-content-center align-items-center">
            <img src={comment?.user?.avatar ?? '/avatar2.png'} alt="comment-img" style={{borderRadius : '50%'}} />
          </div>
          <div className="comment-details">
            <h4>{comment?.user?.username}</h4>
            <p className='m-0'>{comment.comment}</p>
            <p className='m-0'>
              <RateStars rateValue={comment.rating} />
            </p>
          </div>
        </div>
      </div>
  
  );
};

export default CourseComments;
