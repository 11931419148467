import {APIURL} from '../../services/http/api';
import {urlParamReplace} from '../../helpers/UrlParamReplace';
import {EApiParams} from '../../services/http/apiParams.enum';
import {HttpService} from '../../services/http/http.service';
import {getToCart} from '../../store/CartSlice';
import store from '../../store';
import {setLoading} from '../../store/userSlice';

const http = new HttpService();

export const removeCart = async (id: number,is_soldout:any,is_not_available:any) => {
  store.dispatch(setLoading(true));
  const api = APIURL.cart.delete;
  const finalApi = urlParamReplace(api, {[EApiParams.id]: id});
  const [err, resp] = await http.delete(finalApi);
  if (resp) {
    if( is_soldout || is_not_available ){
      window.location.reload();
    }else{
      store.dispatch(getToCart());
      store.dispatch(setLoading(false));
    }
    
  }
  return {error: err, response: resp?.data};
};
