export enum EApiParams {
  id='id',
  date='date',
  time_zone='time_zone',
  page='page',
  taskId='taskId',
  family_member_id='family_member_id',
  childId='childId',
  type='type',
  isMine='isMine',
  time='time',
  start='start',
  end='end',
  duration='duration',
  consultant_id='consultant_id',
  is_consultant='is_consultant',
  tracking_id='tracking_id',
  lang='lang',
}
