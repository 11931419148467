import './style/BankQuestionsList.css';
import './style/BankQuestionsContent.css';
import {Fragment, useState} from 'react';
import {Row} from 'react-bootstrap';
import {Collapse} from 'react-bootstrap';
import {FaArrowUp, FaArrowDown, FaPlus, FaMinus} from 'react-icons/fa';
import {LikeQuestion, WatchQuestion} from './Bank.service';
const BankQuestionsList = ({Questions, loadQuestions}: any) => {
  const [courseSections, setCourseSections] = useState<any[]>([]);
  const [indexSection, setIndex] = useState<any>(null);

  const Like = async (id: number, like: number) => {
    let data = new FormData();
    data.append(`like`, like ? '1' : '0');
    let result = await LikeQuestion(data, id);

    if (result.response.status) {
      console.log('loadQuestions', result.response);
      loadQuestions();
    }
  };

  const Watch = async (id: number) => {
    let result = await WatchQuestion(id);

    if (result.response.status) {
      console.log('watch', result.response);
      loadQuestions();
    }
  };
  return (
    <Fragment>
      <div className="row courses-top-head mb-md-3 align-items-center"></div>

      <div className="courses-list">
        <Row>
          <div>
            {Questions.map((section: any, index: number) => {
              console.log('sectionsectionsection', section);

              return (
                <div className="section-container" key={index}>
                  <div
                    onClick={() => {
                      if (index !== indexSection) {
                        setIndex(index);
                        Watch(section.id);
                      } else if (indexSection !== null) {
                        setIndex(null);
                      } else {
                        setIndex(index);
                      }
                    }}
                    aria-controls="example-collapse-text"
                    aria-expanded={section.collapsed}
                    className="d-flex align-items-center section-item-collapse justify-content-between bankquestionItem">
                    <div className="d-flex align-items-center first-section">
                      <span className="question-icon">
                        {/* <FaRegQuestionCircle size={20} className='icon' /> */}
                        <img src="/icons/quiz.png" />
                      </span>
                      <p className="section-title">{section.question}</p>
                    </div>

                    <div className="d-flex">
                      <div className="d-flex align-items-center bankQuestionRates">
                        <div className="m-2">
                          {/* <FaEye className='icon' /> */}
                          <img src="/icons/open-eye.png" alt="img" />
                          <span className="m-2">{section.views}</span>
                        </div>

                        <div
                          className="m-2"
                          onClick={() => Like(section.id, 1)}>
                          {/* <FaHandHoldingHeart className='icon' /> */}
                          {section.is_liked ? (
                            <img
                              src="/icons/activelike.png"
                              alt="img"
                              width={24}
                            />
                          ) : (
                            <img src="/icons/like.png" alt="img" />
                          )}

                          <span className="m-2">{section.likes}</span>
                        </div>

                        <div
                          className="m-2"
                          onClick={() => Like(section.id, 0)}>
                          {/* <FaHandPointDown className='icon' /> */}
                          {section.is_disliked ? (
                            <img
                              src="/icons/activedislike.png"
                              alt="img"
                              style={{transform: 'rotate(180deg)'}}
                            />
                          ) : (
                            <img src="/icons/dislike.png" alt="img" />
                          )}

                          <span className="m-2">{section.dislikes}</span>
                        </div>
                      </div>

                      <div className="collaped-item m-2">
                        {indexSection == index ? <FaMinus /> : <FaPlus />}
                      </div>
                    </div>
                  </div>
                  <Collapse in={indexSection == index}>
                    <div id="example-collapse-text">
                      <div className="section-item d-flex justify-content-between">
                        <div style={{padding: '0 2rem'}}>{section.answer}</div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              );
            })}
          </div>
        </Row>
      </div>
    </Fragment>
  );
};

export default BankQuestionsList;
