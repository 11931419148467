import {ComponentsProps} from '../interface/ISurvey';
import classes from '../style/Survey.module.css';

const Essay = ({selected}: ComponentsProps) => {
  return (
    <div className={classes.Questions}>
      <textarea
        className={`${classes.QuestionTextarea} w-100 mb-3`}
        onChange={event => selected(event.target.value)}
        rows={7}></textarea>
    </div>
  );
};

export default Essay;
