import classes from '../style/CustomCheckbox.module.css'

const CustomCheckbox = ({index , checked , handleCheckboxChange } : any ) => {
    
   
  return (
  
    <label className={classes.CustomCheckbox} htmlFor={`check-${index}`} >
        <input
            type="checkbox"
            checked={checked}
            onChange={handleCheckboxChange}
        />
        <span className={classes.Checkmark}></span>
    </label>
    
  );
}

export default CustomCheckbox;