import '../style/ConsultantsList.css';
import {Fragment} from 'react';
import {Col, Row} from 'react-bootstrap';
import ConsultantCard from './ConsultantCard';
import Pagination from '../../../UI/pagination/ItemsPagination';
import {ConsultantsListProps} from '../interfaces/IConsultants';
import {useAppSelector} from '../../../hooks/hooks';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import classes from '../style/ConsultantsList.module.css';
import SearchInput from '../../../components/search-input/SearchInput';
import ConsultantCardHorizontal from './ConsultantCardHorizontal';

const ConsultantsList = ({
  setPaginate=true,
  setPage,
  page,
  submitTitle,
}: ConsultantsListProps) => {
  const consultants = useAppSelector(state => state.consultants);

  const handleSearch = (searchTerm: any) => {
    submitTitle(searchTerm);
  };

  const toggleFilter = () => {
    document.getElementById('filterContainer')?.classList.toggle('hidden-filter'); 
  }

  return (
    <Fragment>
      {/*
      <div
        className={`row mb-md-3 justify-content-between align-items-center ${classes.ConsultantsTopHead}`}>
        <SearchInput onSearch={handleSearch} />
        <div className={`${classes.FilterContainer}`}>
        <img
        src="/icons/filter.png"
        className={`${classes.Filter}`}
        onClick={toggleFilter}
        />
        </div>
      </div>
      */}
      <div>
        <div className='mt-md-5'>
          <Row>
            {consultants.allConsultants.map(consultant => {
              return (
                <Col lg="3" className='card-item-container'>
                  <ConsultantCardHorizontal consultant={consultant} />
                </Col>
              );
            })}
            {consultants.allConsultants.length == 0 ? (
              <h3 className="text-center">Sorry, there is no data found.</h3>
            ) : (
              ''
            )}
          </Row>
        </div>
      </div>

      {(!consultants.loading && setPaginate ) && (
        <div className="pagination-container">
          <Pagination
            pages={consultants.lastPage}
            current={page}
            changePage={page => {
              setPage(page);
            }}
          />
        </div>
      )}
    </Fragment>
  );
};

export default ConsultantsList;
