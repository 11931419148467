import { Container } from 'react-bootstrap';

function PrivacyPolicy() {
  return (
  
        <Container>
            
            <h2 className='mt-5 mb-3'>Privacy Policy</h2> 
            <p>We are committed to maintaining the accuracy, confidentiality, and security of your personally identifiable information ("Personal Information"). As part of this commitment, our privacy policy governs our actions as they relate to the collection, use and disclosure of Personal Information.</p>

            <h5 className='mt-5'>1. Introduction</h5>
            <p>We are responsible for maintaining and protecting the Personal Information under our control. We have designated an individual or individuals who is/are responsible for compliance with our privacy policy.</p>

            <h5 className='mt-5'>2. Identifying Purposes</h5>
            <p>We collect, use and disclose Personal Information to provide you with the product or service you have requested and to offer you additional products and services we believe you might be interested in. The purposes for which we collect Personal Information will be identified before or at the time we collect the information. In certain circumstances, the purposes for which information is collected may be clear, and consent may be implied, such as where your name, address and payment information is provided as part of the order process.</p>

            <h5 className='mt-5'>3. Consent</h5>
            <p>Knowledge and consent are required for the collection, use or disclosure of Personal Information except where required or permitted by law. Providing us with your Personal Information is always your choice. However, your decision not to provide certain information may limit our ability to provide you with our products or services. We will not require you to consent to the collection, use, or disclosure of information as a condition to the supply of a product or service, except as required to be able to supply the product or service.</p>

            <h5 className='mt-5'>4. Limiting Collection</h5>
            <p>The Personal Information collected will be limited to those details necessary for the purposes identified by us. With your consent, we may collect Personal Information from you in person, over the telephone or by corresponding with you via mail, facsimile, or the Internet.</p>

            <h5 className='mt-5'>5. Customer Access</h5>
            <p>The Personal Information collected will be limited to those details necessary for the purposes identified by us. With your consent, we may collect Personal Information from you in person, over the telephone or by corresponding with you via mail, facsimile, or the Internet.</p>
            
        </Container>
    
  );
}

export default PrivacyPolicy;