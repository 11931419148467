import axios from 'axios';

export const ErrorHandlingInterceptor = () => {

  

  axios.interceptors.response.use(function (response) {
    //console.log('Error Hanldle12',response);
    if (response.data.code === 201) {
    }
    return response;
  },  (error) =>{
    if (error.response.status === 404) {
      window.location.href = '/not-found'
    }
    //console.log('Error Hanldle1',error.response);
   
    
    return Promise.reject(error);
  });
};
