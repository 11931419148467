import { urlParamReplace } from "../../helpers/UrlParamReplace";
import { APIURL } from "../../services/http/api";
import { EApiParams } from "../../services/http/apiParams.enum";
import { HttpService } from "../../services/http/http.service";


const http = new HttpService();

export const getAllNotifications = async page => {
    
    const api = APIURL.notifications.getAllNotifications;
    const finalApi = urlParamReplace(api, {
      [EApiParams.page]: page,
    });

    try {
      const [err, resp] = await http.get(finalApi);
      console.log('notifications respresp,resp', resp);

      return resp;
    } catch (error) {}
};

export const readOneNotification = async id => {
    const api = APIURL.notifications.readOne;
    const finalApi = urlParamReplace(api, {[EApiParams.id]: id});
    const [err, resp] = await http.put(finalApi);
    console.log('resprespNNNNNN', resp);
  
    return {error: err, response: resp};
  };

export const readAllNotification = async () => {
    const api = APIURL.notifications.readAll;
    const [err, resp] = await http.put(api);
    console.log('resprespNNNNNN222222', resp);
    return {error: err, response: resp};
};