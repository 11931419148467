import {Col, Container, Row} from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import BookedSession from './components/BookedSession';
import {useEffect, useState} from 'react';
import {getConsSessions} from './Consultants.service';
import ItemsPagination from '../../UI/pagination/ItemsPagination';
import {useAppDispatch} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import toast, {Toaster} from 'react-hot-toast';

const ConsultantBookedSessions = () => {
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState<string | null>('upcoming');
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    getSessions();
  }, [tab, page]);

  const getSessions = async () => {
    dispatch(setLoading(true));
    let data = new FormData();
    data.append('is_consultant', '1');
    data.append('is_completed', tab == 'upcoming' ? '0' : '1');
    let result = await getConsSessions(data, page);
    console.log(result, 'resultresultresultresult');

    if (result.response) {
      setLastPage(result.response.data.last_page);
      setData(result.response.data.data);
      setPage(result.response.data.current_page);
      dispatch(setLoading(false));
    }
  };

  return (
    <div style={{background: '#f9f9f9'}}>
      <Container className="mb-5">
        <h1 className="page-title">Booked Lessons</h1>
        <Toaster position="top-right" />
        <Tabs
          defaultActiveKey="upcoming"
          id="uncontrolled-tab-example"
          className="custom-web-tabs mb-5"
          onSelect={(key: string | null) => setTab(key)}>
          <Tab eventKey="upcoming" title="Upcoming">
            <Row>
              {data?.length == 0 ? (
                <h3 className="text-center">Sorry, there is no data found.</h3>
              ) : (
                data.map(data => {
                  return (
                    <Col lg={6} className="mb-4">
                      <BookedSession
                        data={data}
                        tab={tab}
                        toast={(msg: string, type: string) => {
                          if (type == 'error') {
                            toast.error(msg);
                          } else {
                            toast.success(msg);
                          }
                        }}
                      />
                    </Col>
                  );
                })
              )}
            </Row>
          </Tab>
          <Tab eventKey="completed" title="Completed">
            <Row>
              {data?.length == 0 ? (
                <h3 className="text-center">Sorry, there is no data found.</h3>
              ) : (
                data.map(data => {
                  return (
                    <Col lg={6} className="mb-4">
                      <BookedSession data={data} tab={tab} />
                    </Col>
                  );
                })
              )}
            </Row>
          </Tab>
        </Tabs>
        <div className="pagination-container">
          <ItemsPagination
            pages={lastPage}
            current={page}
            changePage={page => {
              setPage(page);
            }}
          />
        </div>
      </Container>
    </div>
  );
};

export default ConsultantBookedSessions;
