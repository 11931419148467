import store from '../../store';
import {GetConsultantsListOptions} from './interfaces/IConsultants.service';
import {APIURL} from '../../services/http/api';
import {urlParamReplace} from '../../helpers/UrlParamReplace';
import {EApiParams} from '../../services/http/apiParams.enum';
import {HttpService} from '../../services/http/http.service';
import {insertConsultantsList} from '../../store/ConsultantsSlice';
import {idParam} from './types';
import moment from 'moment';
import {setLoading} from '../../store/userSlice';
import axios from 'axios';
import {encode as base64Encode} from 'base-64';

const http = new HttpService();

export const getConsultantsList = async ({
  type,
  page,
  load,
  language,
  name,
  selectedDays,
  selectedTime,
  fields,
  is_top,
}: GetConsultantsListOptions): Promise<void> => {
  store.dispatch(setLoading(true));

  let data: {[key: string]: any} = {};
  data.page = page;
  type &&
    type.map((i, index) => {
      data[`types[${index}]`] = i;
    });

  language &&
    language.map((i, index) => {
      data[`languages[${index}]`] = i;
    });

  if (name) {
    data.name = name;
  }
  if (selectedDays) {
    selectedDays?.map((i, index) => {
      data[`days[${index}]`] = moment
        .unix(i.unix)
        .locale('en')
        .format('YYYY-MM-DD');
    });
  }
  if (selectedTime) {
    data.from = moment.unix(selectedTime).locale('en').format('HH:mm:ss');
  }
  if (fields) {
    Object.keys(fields).map((item, index) => {
      data[`fields[${index}]`] = JSON.parse(item);
    });
    let selectedValues = Object.values(fields);
    let index = 0;
    for (let i = 0; i < selectedValues.length; i++) {
      for (let j = 0; j < selectedValues[i].length; j++) {
        data[`specialities[${index}]`] = selectedValues[i][j];
        index++;
      }
    }
  }

  if (typeof is_top !== 'undefined') {
    data.is_top = is_top;
  }

  store.dispatch(insertConsultantsList({data, load}));
};

export const getFields = async (type: string) => {
  const api = APIURL.general.getFields;
  const finalApi = urlParamReplace(api, {[EApiParams.type]: type});

  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};

export const getConsultantData = async (id: idParam) => {
  const api = APIURL.experts.getExpertData;
  const finalApi = urlParamReplace(api, {[EApiParams.id]: id});
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};

export const getSurvey = async (id: idParam) => {
  const api = APIURL.experts.getSurvey;
  const finalApi = urlParamReplace(api, {[EApiParams.id]: id});
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};

export const getSpecAvailability = async (
  start: string,
  duration: number,
  id: number | undefined,
) => {
  const api = APIURL.booking.bookSession;
  const finalApi = urlParamReplace(api, {
    [EApiParams.start]: start,
    [EApiParams.duration]: duration,
    [EApiParams.id]: id,
  });

  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};

export const getConsultantCoursesList = async (
  page: number | string,
  id: number | string,
) => {
  const api = APIURL.experts.getExpertCourse;
  const finalApi = urlParamReplace(api, {
    [EApiParams.consultant_id]: id,
    [EApiParams.page]: page,
  });
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};
export const getConsultantRecordedCoursesList = async (
  page: number | string,
  id: number | string,
) => {
  const api = APIURL.experts.getExpertRecoredCourse;
  const finalApi = urlParamReplace(api, {
    [EApiParams.consultant_id]: id,
    [EApiParams.page]: page,
  });
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const getConsultantFollowUpSysList = async (
  page: number | string,
  id: number | string,
) => {
  const api = APIURL.experts.getExpertTracking;
  const finalApi = urlParamReplace(api, {
    [EApiParams.consultant_id]: id,
    [EApiParams.page]: page,
  });
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const getConsultantInitiativesList = async (
  page: number | string,
  id: number | string,
) => {
  const api = APIURL.experts.getExpertInitiatives;
  const finalApi = urlParamReplace(api, {
    [EApiParams.consultant_id]: id,
    [EApiParams.page]: page,
  });
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const getConsSessions = async (data: FormData, page: number) => {
  const api = APIURL.experts.getSessions;
  const finalApi = urlParamReplace(api, {
    [EApiParams.page]: page,
  });
  const [err, resp] = await http.post(finalApi, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const JoinRoom = async (
  name: string,
  presetName: string,
  meetingId: string,
  picture: string,
  id: number | string,
) => {
  console.log(name, presetName, meetingId, picture, id);
  const uninterceptedAxiosInstance = axios.create();
  let newData = {
    name: name,
    picture: picture,
    preset_name: presetName,
    custom_participant_id: 'ss',
  };
  let user = '0fd8f002-ea7d-48e4-8c68-800af6a97125';
  let password = 'ca1a3b8b1453ef658614';

  const base64encodedData = base64Encode(`${user}:${password}`);
  console.log('base64encodedData', 'Basic ' + base64encodedData);
  let result2 = await uninterceptedAxiosInstance.post(
    `https://api.dyte.io/v2/meetings/${meetingId}/participants`,
    newData,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + base64encodedData,
      },
    },
  );
  console.log('result2result2', result2);
  return {...result2.data};
};
export const getMeetLink = async (link: string) => {
  console.log('ni', link);

  const api = APIURL.general.getMeetLink;
  let data = new FormData();
  data.append('meeting_url', link);

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const SendMail = async (data: FormData) => {
  const [err, resp] = await http.post(APIURL.experts.sendMail, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp.data};
};
export const AttendConsSess = async (id: number) => {
  const api = APIURL.experts.attendConsSess;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: id,
  });
  const [err, resp] = await http.put(finalApi);
  return {error: err, response: resp.data};
};
export const getLanguages = async () => {
  const api = APIURL.general.getLang;
  const [err, resp] = await http.get(api);
  return {error: err, response: resp.data};
};
export const getJobTitles = async () => {
  const api = APIURL.general.getJobTitles;
  const [err, resp] = await http.get(api);
  return {error: err, response: resp.data};
};

export const RegisterAccountConsultant = async (data: FormData) => {
  const api = APIURL.experts.reqeustConsultant;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const EditProfile = async (data: FormData, id: string | number) => {
  const api = APIURL.experts.editProfile;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: id,
  });
  const [err, resp] = await http.post(finalApi, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};
export const ContanctNurabi = async (data: FormData) => {
  const api = APIURL.general.contactUs;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const getConsultantProfile = async (id: number) => {
  const api = APIURL.experts.getConsultantProfile;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: id,
  });
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp.data};
};


export const getSurveyMetting = async (id: number) => {
  const api = APIURL.general.getSurveyMeeting;
  const finalApi = urlParamReplace(api, {[EApiParams.id]: id});

  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};

export const resetPassword = async  (data: FormData) => {
  const [err, resp] = await http.post(APIURL.Home.resetPassword, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {error: err, response: resp.data};
};