import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {APIURL} from '../services/http/api';
import {HttpService} from '../services/http/http.service';

export type languages = {
  id:number
  name:string
}

export const insertLanguages = createAsyncThunk(
  'language/getLanguges',
  async () => {
    const http = new HttpService();
    const api = APIURL.general.getLang;
    try {
      const [err, resp] = await http.get(api);
      return resp;
    } catch (error) {}
  },
);

const LanguageSlice = createSlice({
  name: 'languages',
  initialState: {
    languages: [] as languages[],
  },
  reducers: {},
  extraReducers: {
    [`${insertLanguages.pending}`]: (state, action:PayloadAction<any>) => {},
    [`${insertLanguages.fulfilled}`]: (state, action:PayloadAction<any>) => {
      state.languages = action.payload.data.data;
    },
    [`${insertLanguages.rejected}`]: (state, action:PayloadAction<any>) => {},
  },
});

export default LanguageSlice.reducer;
