import {Col, Container, Row} from 'react-bootstrap';
import './style/Consultant.css';
import ConsultantCard from '../Consultants/components/ConsultantCard';
import MainButton from '../../UI/main-button/MainButton';
import CalenderContainer from '../../components/calender/CalenderContainer';
import {ConsultantsType} from '../Consultants/interfaces/IConsultants';
import {useLocation} from 'react-router';
import {bookFree, getConsultantSlots} from './Booking.service';
import {useEffect, useState} from 'react';
import {SlotData} from './interface/IBooking';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import moment from 'moment';
import toast, {Toaster} from 'react-hot-toast';
import {colors} from '../../constants/colors';
import {useNavigate} from 'react-router-dom';
import {Questionnaire} from '../Consultants/interfaces/IQuestionnaire';
import {setLoading} from '../../store/userSlice';
import {number} from 'yargs';

const FreeSession = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const consultant: ConsultantsType = location.state.consultant;
  const survey: Questionnaire = location.state.survey;
  const cart = useAppSelector(state => state.cart.data);
  const [selectedSlots, setSelectedSlots] = useState<SlotData>();

  const [allSlotsReserved, setAllSlotsReserved] = useState(false);

  const [savedDate, setSavedDate] = useState(
    moment().locale('en').format('YYYY-MM-DD'),
  );
  const getSlotTime = (time: any): any => {
    let hour = time?.slice(0, time.indexOf(':'));
    let min = time?.slice(time.indexOf(':') + 1, time.length);

    if (time) {
      return {
        date: ` ${hour > 12 ? hour - 12 : hour}:${min > 9 ? min : `${min}`} ${
          hour >= 12 ? 'PM' : 'AM'
        }`,
      };
    }
  };
  const [slots, setSlots] = useState([]);
  useEffect(() => {
    getSlots(savedDate);
  }, []);

  const slotColor = (id: number, reserved: number) => {
    if (selectedSlots?.id == id) return colors.chamThirdColor;
    else if (reserved == 1) return colors.regularGray;
    else return colors.white;
  };

  const anySlotExistInCart = () => {
    return cart.find(i => i.model_name == 'ConsultationSession') ? true : false;
  };

  const thisSlotExistInCart = (consultation_session_id: number): any => {
    return cart.find(
      i =>
        i.model_id == consultation_session_id &&
        consultation_session_id != null,
    );
  };

  const enrollSessionFree = async () => {
    dispatch(setLoading(true));
    // let token = await AsyncStorage.getItem('token');
    try {
      let data = new FormData();
      data.append(
        'consultation_session_id',
        String(selectedSlots?.consultation_session_id),
      );
      let result = await bookFree(data);

      if (result?.response?.success) {
        // enrollFree.current.close();
        dispatch(setLoading(false));
        if (survey) {
          navigate('/survey', {state: {survey: survey}});
        } else
          navigate('/payment-result?payment_success=1', {
            state: {type: 'freeSession'},
          });
        // navigation.dispatch(
        //   StackActions.replace('PaymentResult', {
        //     status: true,
        //     type: 'ReqTime',
        //     data: {
        //       ...item,
        //       selectedTime: selectedSlots,
        //       freeSession: true,
        //       selectedDay: day == '' ? moment() : day,
        //       surv: route.params.surv,
        //     },
        //   }),
        // );
      } else {
        dispatch(setLoading(false));
        toast.error(result?.response?.msg);
      }
    } catch (e) {
      dispatch(setLoading(false));
      toast.error('errPay');
      console.log({e});
    }
    // dispatch(setLoad(false));
  };

  const getSlots = async (date: any) => {
    // setLoadingSlots(true);
    // let token = await AsyncStorage.getItem('token');
    dispatch(setLoading(true));
    try {
      let data = new FormData();
      data.append('user_id', String(consultant.id));
      data.append('date', date);
      let result = await getConsultantSlots(data);

      console.log(result.response.data, 'j');

      if (result.response.status) {
        dispatch(setLoading(false));
        setSlots(result.response.data);
        // setLoadingSlots(false);
        // setRefreshing(false);
        if (result.response.data.length) {
          const hasallSlotsReserved = result.response.data.find(
            (item: any) => item.reserved == 0,
          );

          if (hasallSlotsReserved) {
            setAllSlotsReserved(false);
          } else {
            setAllSlotsReserved(true);
          }
        }
      }

      // dispatch(setLoad(false));
    } catch (e) {
      console.log({e});
      // dispatch(setLoad(false));
    }
    // setLoadingCalendar(false);
  };

  return (
    <div className="mt-md-5">
      <Container>
        <Toaster position="top-right" />
        <Row className="d-flex justify-content-between">
          <Col xs={3}>
            <ConsultantCard
              consultant={consultant}
              // specs={
              //   <Fragment>
              //     <div
              //       className="d-flex align-items-center mt-4 d-none"
              //       style={{
              //         justifyContent: 'space-evenly',
              //         borderBottom: '1px solid #eee',
              //         paddingBottom: '20px',
              //       }}>
              //       <div>
              //         <FaClock color="#fb5465" style={{fontSize: '1.5rem'}} />{' '}
              //         300 Min
              //       </div>

              //       <div>
              //         <FaDollarSign
              //           color="#65c890"
              //           style={{fontSize: '1.5rem'}}
              //         />
              //         20 $
              //       </div>
              //     </div>
              //     <div className="mt-3">
              //       {/*<p
              //                           style={{
              //                               color: '#fb5465',
              //                               fontWeight: '600'
              //                           }}
              //                       >
              //                           Ahmed Completed 30 Successful Session
              //                       </p>*/}
              //       <MainButton text="Request Consultation" />
              //     </div>
              //   </Fragment>
              // }
            />
          </Col>

          <Col xs={9} className="text-center">
            <h4 className="mb-3">
              <strong>Available Times</strong>
            </h4>

            <CalenderContainer
              date={savedDate}
              onChange={(i: any) => {
                setSavedDate(moment(i).locale('en').format('YYYY-MM-DD'));
                getSlots(moment(i).locale('en').format('YYYY-MM-DD'));
              }}
            />

            <p className="mb-4 mt-3">
              <strong style={{color: 'red'}}>
                You can only get the first lesson for free, but you can not add it
                to the cart or book other seesions
              </strong>
            </p>
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <Row className="w-100">
                {slots.map((data: SlotData) => (
                  <Col
                    md="4"
                    className="mb-4 hahah"
                    onClick={async () => {
                      let now = moment(new Date())
                        .locale('en')
                        .format('YYYY-MM-DD HH:m:s');
                      let nowMoment = moment.utc(now, 'YYYY-MM-DD[T]HH:mm[Z]');

                      let todayDateAndTime = data.from_date;
                      let todayDateAndTimeMoment = moment.utc(
                        todayDateAndTime,
                        'YYYY-MM-DD[T]HH:mm[Z]',
                      );
                      if (data?.reserved == 1) {
                        toast.error('Lesson Already Booked');
                      } else if (data?.is_margined == 1) {
                        toast.error('Lesson in margin');
                      } else if (
                        moment(todayDateAndTimeMoment)
                          .locale('en')
                          .isAfter(nowMoment)
                      ) {
                        setSelectedSlots(data);
                      } else {
                        toast.error('Can’t book session in previous time');
                      }
                    }}>
                    <div
                      style={{
                        backgroundColor: slotColor(data?.id, data?.reserved),
                      }}
                      className="d-flex align-items-center slotContainer">
                      <div className="text-center" style={{width: '75%'}}>
                        <div className="text-center">
                          <strong>
                            {
                              getSlotTime(
                                data?.from_date.slice(
                                  11,
                                  data.from_date.length - 3,
                                ),
                              ).date
                            }
                          </strong>
                        </div>
                        <Row>
                          <Col>
                            <img
                              src="/icons/clock.png"
                              alt="img"
                              width={20}
                              style={{marginRight: '5px'}}
                            />
                            {`${data.duration}Min`}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                ))}

                <Col md="12">
                  <MainButton
                    text="Enroll Free"
                    onClick={enrollSessionFree}
                    disabled={
                      slots.length == 0 || allSlotsReserved || !selectedSlots
                    }
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FreeSession;
