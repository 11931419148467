export const getDomain = () => {
  
    let domainName =''
    let hostName = window.location.hostname

    if( hostName == 'localhost' ){
        domainName = 'https://testing.nualim.com'
    }

    else if( hostName == 'dev.nualim.com' ){
        domainName = 'https://development.nualim.com' 
    }

    else if( hostName == 'test.nualim.com' ){
        domainName = 'https://testing.nualim.com'
    }

    else if(hostName == 'prod.nualim.com' || hostName == 'nualim.com'){
        domainName = 'https://api.nualim.com'
    }
    
    else{
        domainName = 'https://dev.nualim.com'
    }
    
    
    return domainName
  
};
