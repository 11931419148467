import {Col, Container, Row} from 'react-bootstrap';
import {useAppDispatch} from '../../hooks/hooks';
import {useEffect, useState} from 'react';
import {setLoading} from '../../store/userSlice';
import ItemsPagination from '../../UI/pagination/ItemsPagination';
import SessionCard from './components/SessionCard';
import { getRequestsList } from './Booking.service';
import { NavLink } from 'react-router-dom';


const PrivateSessionsList = ({isCons = 1 }) => {


  const dispatch = useAppDispatch();

  const [items, setItems] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [page, setPage] = useState(1);
 

  useEffect(() => {
    getData();
  }, [page]);

  
  const getData = async () => {
    dispatch(setLoading(true));
    let result = await getRequestsList(page , isCons);
    console.log('result sessions', result);
    setItems(result.response.data.data);
    setLastPage(result.response.data.last_page);
    dispatch(setLoading(false));
  };



 

  return (
    <div className='mt-md-5'>
      <Container>
      
        
      
      
      <Col xs={12}>
        <Row>
         
        { items.length ? items.map((item) => {
          return (
            <Col lg={6} className='p-0'>
                    <NavLink to={`/private/sessions/${item.id}/details/${isCons}`} style={{color: 'initial'}}>
                      <SessionCard
                      name={item.user_name ?? ''}
                      avatar={item.avatar}
                      created_at={item.created_at}
                      status={item.status}
                      isCons={isCons}
                      user_id={item.user_id}
                      />
                    </NavLink>
                  </Col>
                );
              })
            :
            <div className='text-center'>
              <img src="/logo.png" alt="img" width={70} />
              <h3 className="text-center mt-3">Sorry, there is no data found.</h3>
            </div>
            }
              <Col lg={12}>
           
           {items.length ? (

          
            <div className="pagination-container">
              <ItemsPagination
                pages={lastPage}
                current={page}
                changePage={page => {
                  setPage(page);
                }}
              />
            </div>
             ) 
            :
            ''
            }
            </Col>
              </Row>
            </Col>
            
          </Container> 

          
    </div>
  );
};

export default PrivateSessionsList;
