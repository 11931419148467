import RateStars from '../../UI/rate-stars/RateStars';
import SourceViews from '../../UI/source-views/SourceViews';
import {RateProp} from './IRate';
import './RateAndPrice.css';
const RateAndPrice = ({ratings, ratings_count, subscribers}: RateProp) => {
  return (
    <div className="rate-content d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center course-rating">
        <RateStars rateValue={ratings} />

        <div className="rate-number mx-2">
          ({ratings}) {ratings_count} Rate
        </div>
      </div>

      <SourceViews numbers={subscribers} />
    </div>
  );
};

export default RateAndPrice;
