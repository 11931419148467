import {Col, Container, Row} from 'react-bootstrap';
import './style/Consultant.css';
import ConsultantCard from '../Consultants/components/ConsultantCard';
import MainButton from '../../UI/main-button/MainButton';
import CalenderContainer from '../../components/calender/CalenderContainer';
import {ConsultantsType} from '../Consultants/interfaces/IConsultants';
import {useLocation} from 'react-router';
import {addCart, getConsultantSlots} from './Booking.service';
import {useEffect, useState} from 'react';
import {SlotData} from './interface/IBooking';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import moment from 'moment';
import toast, {Toaster} from 'react-hot-toast';
import {getToCart} from '../../store/CartSlice';
import {useNavigate} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

const ConsultantSessions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const consultant: ConsultantsType = location.state.consultant;
  const cart = useAppSelector(state => state.cart.data);
  const dispatch = useAppDispatch();
  console.log('consultant222222', consultant);
  const [selectedSlots, setSelectedSlots] = useState<SlotData[]>([]);
  const [savedDate, setSavedDate] = useState<any>(
    new Date(),
  );
  const getSlotTime = (time: any): any => {
    let hour = time?.slice(0, time.indexOf(':'));
    let min = time?.slice(time.indexOf(':') + 1, time.length);

    if (time) {
      return {
        date: ` ${hour > 12 ? hour - 12 : hour}:${min > 9 ? min : `${min}`} ${
          hour >= 12 ? 'PM' : 'AM'
        }`,
      };
    }
  };
  const [slots, setSlots] = useState([]);
  useEffect(() => {
    getSlots(savedDate);
  }, []);

  const slotColor = (
    id: number,
    reserved: number,
    consultation_session_id: number,
  ) => {
    if (thisSlotExistInCart(consultation_session_id)) return 'blue';
    else if (selectedSlots.find(items => items.id == id)) return 'red';
    else if (reserved == 1) return 'gray';
    else return 'white';
  };

  const anySlotExistInCart = () => {
    return cart.find(i => i.model_name == 'ConsultationSession') ? true : false;
  };

  const thisSlotExistInCart = (consultation_session_id: number): any => {
    return cart.find(
      i =>
        i.model_id == consultation_session_id &&
        consultation_session_id != null,
    );
  };

  const getSlots = async (date: any) => {
    // setLoadingSlots(true);
    // let token = await AsyncStorage.getItem('token');
    try {
      let data = new FormData();
      data.append('user_id', String(consultant.id));
      data.append('date', date);
      let result = await getConsultantSlots(data);

      console.log(result.response.data, 'j');

      if (result.response.status) {
        setSlots(result.response.data);
        // setLoadingSlots(false);
        // setRefreshing(false);
      }

      // dispatch(setLoad(false));
    } catch (e) {
      console.log({e});
      // dispatch(setLoad(false));
    }
    // setLoadingCalendar(false);
  };

  const addToCart = async () => {
    let data: FormData = new FormData();
    selectedSlots.map((i: SlotData, index) => {
      data.append(
        `items[${index}][model_id]`,
        String(i.consultation_session_id),
      );
      data.append(`items[${index}][model_name]`, 'ConsultationSession');
    });
    let result = await addCart(data);
    if (result.response.status) {
      dispatch(getToCart());
    }
    console.log('resultresultresultresult', result);
  };

  //show more sessions pop up
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="mt-md-5">
      <Container>
        <Toaster position="top-right" />
        <Row className="d-flex justify-content-between">
          <Col xs={3}>
            <ConsultantCard consultant={consultant} />
          </Col>

          <Col xs={9} className="text-center">
            <h4 className="mb-3">
              <strong>Available Times</strong>
            </h4>

            <CalenderContainer
              date={savedDate}
              onChange={(i: any) => {
                setSavedDate(new Date(i));
                getSlots(moment(i).locale('en').format('YYYY-MM-DD'));
              }}
            />

            <p className="mb-4 mt-3">
              <strong style={{color: 'red'}}>
                You can only get the first lesson for free, but you can not add it
                to the cart or book other seesions
              </strong>
            </p>
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <Row className="w-100">
                {slots.map((data: SlotData) => (
                  <Col
                    md="4"
                    className="mb-4 hahah"
                    onClick={async () => {
                      if (!thisSlotExistInCart(data?.consultation_session_id)) {
                        let now = moment(new Date())
                          .locale('en')
                          .format('YYYY-MM-DD HH:m:s');
                        let nowMoment = moment.utc(
                          now,
                          'YYYY-MM-DD[T]HH:mm[Z]',
                        );

                        let todayDateAndTime = data.from;
                        let todayDateAndTimeMoment = moment.utc(
                          todayDateAndTime,
                          'YYYY-MM-DD[T]HH:mm[Z]',
                        );
                        if (data?.reserved == 1) {
                          toast.error('Lesson Already Booked');
                        } else if (data?.is_margined == 1) {
                          toast.error('Lesson Margin');
                        } else if (
                          moment(todayDateAndTimeMoment)
                            .locale('en')
                            .isAfter(nowMoment)
                        ) {
                          let arr = [...selectedSlots];

                          if (
                            arr.findIndex(items => items.id == data.id) >= 0
                          ) {
                            setSelectedSlots(
                              arr.filter(items => items.id != data.id),
                            );
                          } else {
                            setSelectedSlots([
                              ...selectedSlots,
                              {
                                ...data,
                                model_id: data?.consultation_session_id,
                                model_name: 'ConsultationSession',
                              },
                            ]);
                          }
                        } else {
                          toast.error('Can’t book lesson in previous time');
                        }
                      } else {
                        toast.error('already In Cart');
                      }
                    }}>
                    <div
                      style={{
                        backgroundColor: slotColor(
                          data?.id,
                          data?.reserved,
                          data?.consultation_session_id,
                        ),
                      }}
                      className="d-flex align-items-center slotContainer">
                      <div className="text-center" style={{width: '75%'}}>
                        <div className="text-center">
                          <strong>
                            {
                              getSlotTime(
                                data?.from.slice(11, data.from.length - 3),
                              ).date
                            }
                          </strong>
                        </div>
                        <Row>
                          <Col>
                            <img
                              src="/icons/clock.png"
                              alt="img"
                              width={20}
                              style={{marginRight: '5px'}}
                            />
                            {`${data.duration}Min`}
                          </Col>
                          <Col>9 USD</Col>
                        </Row>
                      </div>

                      <div
                        className="text-center rightBtn"
                        onClick={handleShow}>
                        More <br /> Lessons
                      </div>
                    </div>
                  </Col>
                ))}

                <Col md="12">
                  <MainButton text="Add Cart" onClick={addToCart} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose}>
        {/*<Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>*/}
        <Modal.Body>
          <Form.Check type="checkbox" id={`all`}>
            <Form.Check.Input type="checkbox" isValid />
            <Form.Check.Label>Select All</Form.Check.Label>
          </Form.Check>

          <div
            className="allSessionsContainer"
            style={{
              maxHeight: '500px',
              overflowY: 'auto',
            }}>
            <div
              style={{
                marginTop: '10px',
                border: '2px solid #eee',
                borderRadius: '15px',
                padding: '1rem',
                marginRight: '10px',
              }}>
              <Form.Check type="checkbox" id={`saturday`}>
                <Form.Check.Input type="checkbox" isValid />
                <Form.Check.Label>
                  <span style={{color: '#0576e3'}}>Saturday</span>
                </Form.Check.Label>
              </Form.Check>
              {[...Array(20)].map((e, i) => (
                <div
                  key={i}
                  className="pop-up-des"
                  style={{
                    padding: '1rem',
                  }}>
                  <div className="d-flex align-items-center">
                    <Form.Check type="checkbox" id={`saturday`}>
                      <Form.Check.Input type="checkbox" isValid />
                    </Form.Check>
                    <div
                      style={{
                        padding: '1rem',
                        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                        borderRadius: '10px',
                        marginLeft: '15px',
                        display: 'flex',
                        justifyContent: 'start',
                        flex: '100%',
                        alignItems: 'center',
                      }}>
                      <div>
                        <img
                          src="/calendar.png"
                          alt="calendar"
                          style={{
                            width: '20px',
                            height: '20px',
                            verticalAlign: 'sub',
                          }}
                        />
                        <span> 12.30 - 01:00 Am </span>
                      </div>
                      <span style={{marginLeft: '15px'}}> 9 September </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <MainButton text="Add To Cart" />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConsultantSessions;
