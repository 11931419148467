import ReactStars from 'react-rating-stars-component';
import classes from './RateStars.module.css'
function RateStarsEdit({ratingChanged}) {
  
  return (
    <span className={`${classes.RateStars}`} >
      <ReactStars
        count={5}
        size={24}
        activeColor="#ffd700"
        onChange={ratingChanged}
        isHalf={true}
      />
    </span>
  );
}

export default RateStarsEdit;
