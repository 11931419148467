import {Fragment, useRef, useState} from 'react';

import {Col, Container, Image, Row} from 'react-bootstrap';

import './Home.css';

import {useLocation, useNavigate} from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {useEffect} from 'react';
import axios from 'axios';
import { getDomain } from '../../helpers/getDomain';
import { auth } from '../../Screens/Consultants/components/firebase';
const Banners = () => {

  const [banner, setBanner] = useState(null);

  useEffect(() => {
    getBanners()
  }, []);

  const getBanners = async () => {
    
    let token = localStorage.getItem('token');
    
    let url = `/api/mobile/sliders-panners/get/panners`
   
    try {
      let result = await axios.get(getDomain() + url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('bannar', result.data.data.image);  
      setBanner(result.data.data.image)
        
    } catch (e) {
      
      console.log(e);
    }
  };

 

  const navigate = useNavigate();
 
  return (
    <Fragment>
      <Container>
        <Row>
        <Col md="12">
          {
            banner && (
              <div className="pt-5 mt-5 mb-5 pb-5">
                <Image src={banner} className='w-100' rounded />
              </div>
            )
          }
        </Col>
      </Row>
      </Container>
    </Fragment>
  );
};

export default Banners;
