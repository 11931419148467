import Pagination from 'react-bootstrap/Pagination';
import './ItemsPagination.css';
import { PaginateProps } from '../../Screens/Courses/interfaces/IPaginate';

function ItemsPagination({ pages, current, changePage }: PaginateProps) {
  return (
    <Pagination className="mt-5 mb-5 justify-content-center" style={{ flexWrap: 'wrap' }}>
      <Pagination.Prev
        onClick={() => {
          window.scrollTo(0, 0);
          changePage(current - 1);
        }}
        disabled={current === 1}
      >
        Prev
      </Pagination.Prev>

      {Array(pages)
        .fill(null)
        .map((i, index) => {
          return (
            <Pagination.Item
              style={{ marginBottom: '10px' }}
              onClick={() => {
                window.scrollTo(0, 0);
                changePage(index + 1);
              }}
              active={index + 1 === current}
              key={index + 1}
            >
              {index + 1}
            </Pagination.Item>
          );
        })}

      <Pagination.Next
        onClick={() => {
          window.scrollTo(0, 0);
          changePage(current + 1);
        }}
        disabled={current === pages}
      >
        Next
      </Pagination.Next>
    </Pagination>
  );
}

export default ItemsPagination;