import {Card, Col, Container, Row} from 'react-bootstrap';
import {useNavigate, useLocation, useParams} from 'react-router-dom';

import {useEffect, useState} from 'react';
import { getFamily } from './family.services';
import { NavLink } from 'react-router-dom';
import { FaPlus, FaRegCheckSquare } from 'react-icons/fa';
import { colors } from '../../constants/colors';
import { getAllTasks } from './tasks.services';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import { setLoading } from '../../store/userSlice';
import { useAppDispatch } from '../../hooks/hooks';

const UserTasks = () => {

  const {t} = useTranslation();

  const dispatch = useAppDispatch();
  
  const navigate = useNavigate();
  const [data, setDate] = useState([]);
  
  
  useEffect(() => {
    getUserTasks()
  }, []);
  

  const getUserTasks = async () => {
    dispatch(setLoading(true));
    let data = new FormData();
    let result = await getAllTasks(data);
    if (result?.response?.status) {
      dispatch(setLoading(false));
      console.log('user tasks' , result?.response?.data )
      setDate(result?.response?.data);
    }else{
      dispatch(setLoading(false));
    }
  };

  return (
    <div>
      <Container>
        <Row className="d-flex">
        {data.map((a: any, i) => {
            return (
              <Col md="12" className="mt-4" key={i} 
              onClick={() => {
                navigate(
                  `/task-details/${a.id}/${a.family_member_id}/` );
              }}
              >
                 <Card style={{
                  padding: '1rem 2rem' ,
                  borderColor: colors.darkGreen ,
                  marginBottom: '2rem',
                  background: a?.is_task_completed == 1 ? colors.darkGreen : colors.white,
                  cursor: 'pointer' }}>
            <div
                className=""
                >
                <div>
                  <img
                    src={a?.
                      consultant_avatar  ?? '/icons/manHolder.png'}
                    style={{
                      width: '50px',
                      height: '50px',
                      margin: '0 10px',
                      borderRadius: '50%',
                      
                    }}
                  />
                  <span>{a?.consultant_name}</span>
                </div>  

                <h4 style={{margin: '10px'}}>{a?.name_en ?? a?.name_ar }</h4>

                <hr />

                <div
                className='d-flex justify-content-between'
                style={{margin: '20px 10px 10px 10px'}}
                >
                  <div>
                    
                    <div>
                      {moment(a?.task_start_date).locale('en').format('DD MMM YYYY')} - {moment(a?.task_end_date).locale('en').format('DD MMM YYYY')}
                    </div>
                  </div>

                  { a?.member_family ?   
                  <div>
                    <span
                    style={{padding: '10px' , background: colors.selected , borderRadius: '15px' }}
                    >
                      {a?.member_family}
                    </span>
                  </div>
                  : '' }
                </div>
                
              </div>
              </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default UserTasks;
