import { Col, Container, Row } from "react-bootstrap";
import MainButton from "../UI/main-button/MainButton";

const NotAvailable = () => {
  

  return (
   <Container>
    <Row>
      <Col md={{span: 6, offset: 3}} className="text-center pt-5 pb-5">
        <img src="/not-available.png" alt="img"  style={{maxWidth: '60%'}}/>
        <h3 className="not-available-title">This product is not available for your country</h3>
        <div className="mt-5" onClick={() => { window.location.href = '/'  } }>
          <MainButton text="back to home" />
        </div>
      </Col>
    </Row>
   </Container>
  );
};

export default NotAvailable;
