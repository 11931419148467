import moment from 'moment';
import {APIURL} from '../../services/http/api';
import {HttpService} from '../../services/http/http.service';
import {date} from './interface/IBooking';
import { urlParamReplace } from '../../helpers/UrlParamReplace';
import { EApiParams } from '../../services/http/apiParams.enum';

const http = new HttpService();

export const getConsultantSlots = async (data: any) => {
  const api = APIURL.experts.getSlots;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const addCart = async (data: any) => {
  const api = APIURL.cart.add;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const bookFree = async (data: any) => {
  const api = APIURL.booking.bookFree;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const sendRequestSession = async (
  id: string,
  dates: date[],
  reason: string,
) => {
  console.log('dates', dates);

  const api = APIURL.experts.requestSession;
  let data = new FormData();
  data.append('reason', reason);
  data.append('consultant_id', String(id));
  dates?.map((i: date, index: number) => {
    data.append(
      `requests[${index}][day]`,
      moment(i.date).locale('en').format('YYYY-MM-DD'),
    );
    data.append(
      `requests[${index}][from]`,
      moment(i.startTime).locale('en').format('HH:mm:ss'),
    );
    data.append(
      `requests[${index}][to]`,
      moment(i.endTime).locale('en').format('HH:mm:ss'),
    );
  });

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};


export const getRequestsList = async (page:number,isCons:number) => {
  const api = APIURL.experts.getRequestsList;
  const finalApi = urlParamReplace(api, {
    [EApiParams.page]: page,
    [EApiParams.is_consultant]: isCons,
  });
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};

export const getRequestsDetails = async (id:number) => {
  const api = APIURL.experts.getRequestDetails;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: id,
  });
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};

export const acceptRequestSession = async (id:number) => {
  const api = APIURL.experts.acceptRequest;
  let data = new FormData();
  data.append('consultant_request_id', String(id));

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const rejectRequestSession = async  (id:number) => {
  const api = APIURL.experts.rejectRequest;
  let data = new FormData();
  data.append('consultant_request_id', String(id));

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};