import { Col, Container, Row } from "react-bootstrap"
import classes from './AboutUs.module.css'


const AboutUs = () => {
    return (
        <Container className="main-margin-top">
            <Row>
                <Col md={12} className="d-flex align-items-center pb-5">
                    <div className={classes.TermsDetails}>
                        <h1 className="main-title text-center mb-5">About Us</h1>
                        

                        <p>
                        <b>Nualim</b> is a Global Teachers’ Platform. It’s the first Global Platform which is designed especially for Teachers to help them organize their work automatically, professionally and accurately.
                        </p>

                        <p>
                        <b>Nualim</b> seeks empowering Teachers and equipping them with effective tools that automatically organize their work and elevate their Students' learning experiences.
                        </p>

                        <p>
                        <b>Nualim</b> serves as a unique Global Platform that seamlessly connects Teachers with diverse disciplines with eager Students seeking their expertise. It provides a secure and convenient hub for Students worldwide to find and connect with qualified Teachers in any field.
                        </p>

                        <p>
                        <b>Nualim</b> empowers Teachers with personal accounts to expertly manage their schedules, income, and secure payments through a safe and efficient electronic system. A dedicated reminder system for both Teachers and Students ensures timely notifications for classes, promoting efficiency and seamless learning experiences.
                        </p>

                        <p>
                        <b>Nualim</b> facilitates both individual and group lessons via secure video conference technology, enabling Teachers to connect with Students from anywhere in the world, fostering an interactive learning environment.
                        </p>

                        <p>
                        Through <b>Nualim</b> Teachers can transform their curricula into recorded courses which help expanding their reach to a wider audience and sharing their knowledge with the world.
                        </p>

                        <p>
                        <b>Nualim</b> enables Teachers to organize live courses for groups of Students which promote direct interaction between Teachers and Students, encourage healthy competition among Students, and enrich learning experiences.
                        </p>

                        <p>
                        <b>Nualim</b> fosters active learning by empowering Teachers to transform curricula into challenges and competitions which motivate Students to engage and transform learning into an enjoyable and stimulating experience.
                        </p>

                        <p>
                        <b>Nualim</b> provides comprehensive student tracking systems that enable Teachers to monitor Students’ progress, identify strengths and weaknesses, and tailor lesson plans accordingly.
                        </p>

                        <p>
                        <b>Nualim</b> provides electronic assessments for Teachers to evaluate their Students and track their performance.
                        </p>

                        <p>
                        <b>Nualim</b> fosters trust and loyalty between Teachers and students by providing a secure electronic Platform that facilitates seamless communication, ensures transparent payment processing, and streamlines scheduling and managing lessons.
                        </p>

                        <p>
                            <b>Nualim</b> offers round-the-clock technical support to assist Teachers and Students with any queries or technical issues.
                        </p>

                        <p>
                            <b>Nualim</b> transcends the boundaries of a mere electronic platform; it serves as an active partner in educational development and Teacher empowerment by providing the necessary tools and resources to organize Teachers’ work, connect with students, and enhance learning experiences, <b>Nualim</b> contributes to shaping a brighter future for education.
                        </p>

                        <h4>Join Nualim Community Today and start a journey of educational excellence!</h4>
                        <h4>Nualim is Your Destination for Exceptional Teaching Experiences.</h4>



                        

                        

                        

                       







                        
                        
                    </div>
                </Col>
                
            </Row>
        </Container>
    )
}


export default AboutUs