import {Navigate} from "react-router-dom"

const LoggedInRoute = ({children}) => {
    const isLoggedIn = localStorage.getItem('logged') ;
    if(isLoggedIn != '1' ) {
        return <Navigate to="/login"  />
    }
 return children

};

export default LoggedInRoute;