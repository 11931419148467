import { useState } from 'react';
import classes from './SearchInput.module.css'
const SearchInput = ({ onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
        onSearch(searchTerm);
        }
    };

    const handleImgClick = () => {
      
      onSearch(searchTerm);
      
  };


    return (
        <div className="col-12 p-md-0">
        <div className="search-container">
          <img
            src="/icons/search-normal.png"
            className="search-icon"
            alt="img"
            width={20}
            onClick={handleImgClick}
          />
          <input
            type="text"
            placeholder="3 Characters at least"
            className={` ${classes.ConsultantsListSearch} form-control m-0 `}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            
          />
        </div>
      </div>
    )
}


export default SearchInput