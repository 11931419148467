import { urlParamReplace } from '../../helpers/UrlParamReplace';
import { APIURL } from '../../services/http/api';
import { EApiParams } from '../../services/http/apiParams.enum';
import { HttpService } from '../../services/http/http.service';

const http = new HttpService();

export const getAllTasks = async (data) => {
  const api = APIURL.tasks.getAllTasks;
  const [err, resp] = await http.post(api,data);

  return {error: err, response: resp?.data};
};

export const getTaskDetails = async (taskId, data) => {
  const api = APIURL.tasks.getTaskDetails;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: taskId,
  });
  const [err, resp] = await http.post(finalApi, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {error: err, response: resp?.data};
};

export const getFilterData = async () => {
  const api = APIURL.tasks.getFilterData;
  const [err, resp] = await http.get(api);

  return {error: err, response: resp?.data};
};

export const addNote = async data => {
  const api = APIURL.tasks.addNote;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {error: err, response: resp?.data};
};
export const editNote = async data => {
  const api = APIURL.tasks.editNote;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {error: err, response: resp?.data};
};

export const sendProf = async data => {
  const api = APIURL.tasks.sendProf;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {error: err, response: resp?.data};
};
export const getAllNotes = async data => {
  const api = APIURL.tasks.getAllNotes;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {error: err, response: resp?.data};
};

export const sendAttach = async data => {
  const api = APIURL.tasks.sendAttach;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {error: err, response: resp?.data};
};
export const autoUpdate = async data => {
  const api = APIURL.tasks.autoUpdate;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {error: err, response: resp?.data};
};

export const getAllTaskNotes = async data => {
  const api = APIURL.tasks.getAllNotes;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {error: err, response: resp?.data};
};
