import {Badge, Col, Container, Row} from 'react-bootstrap';
import classes from './style/Survey.module.css';
import {useLocation, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';

import {useAppDispatch} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import { NavLink } from 'react-router-dom';
import { getUserSurveyList } from './Survey.services';

const UserSurveyList = () => {
 
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  

  useEffect(() => {
    getData()
  }, []);

  
  const getData = async () => {
    dispatch(setLoading(true));
    let result = await getUserSurveyList();
    console.log('resultresult2', result);
    if (result?.response?.status) {
        console.log('User Questionnare List' , result?.response?.data )
        setData(result?.response?.data)
        dispatch(setLoading(false));
      
    }
  };
  

  useEffect(() => {
    
  }, []);

  
  return (
    <Container className="mb-5 mt-4">
      <Row>

      {data.map( (item:any) => {
              return (
                <Col md={12} className='p-0'>
                  <NavLink to={ item?.is_answered ? '#' : `/survey/${item?.consultant_id}` } style={{ color: '#000' }}>  
                  <div style={{
                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                    borderRadius: '10px',
                    marginBottom: '25px',
                    cursor: 'pointer',
                    position: 'relative',
                    display: 'flex',
                    padding:'2rem',
                    background: '#fff'
                  }}>

                  <img
                  src="/personality2.png"
                  style={{
                    width:'80px',
                    height: '80px',
                    margin: '0 2rem 0 0'
                  }}
                  />

                  <div>
                    <h3>{item?.consultant_name}</h3>
                    <h4 style={{ color: 'gray' }}>{item?.title}</h4>
                    {
                      item?.is_answered == false && (
                        <div
                          style={{ 
                            background: '#FED21A',
                            color: '#A5243D' ,
                            padding: '10px 20px',
                            marginTop: '15px',
                            borderRadius: '5px',
                            width:'fit-content',


                          }}>
                            Not Answered
                        </div>
                      )
                    }

                    {
                      item?.is_answered == true && (
                        <div
                          style={{ 
                            background:'#EBF8F0',
                            color: '#39B970' ,
                            padding: '10px 20px',
                            marginTop: '15px',
                            borderRadius: '5px',
                            width:'fit-content',
                          }}>
                            Completed
                        </div>
                      )
                    }
                    
                  </div>

                  </div>
                  </NavLink>
                </Col>
              );
            })}

            {
              data.length == 0 && (
                <div className='text-center'>
                  <img src="/logo.png"  width={70}/>
                  <h2 className='mt-3'>No Records Found</h2>
                </div>
              )
            }

      
      </Row>
    </Container>
  );
};

export default UserSurveyList;
