import {Container, Row, Col} from 'react-bootstrap';
import BankQuestionsList from '../../Screens/BankQuestions/BankQuestionsList';
import {useNavigate} from 'react-router-dom';
const UserBankQuestions = () => {
  const navigate = useNavigate();

  function handleClick() {
    navigate('/bank-question/form');
  }

  return (
    <div>
      <Container>
        <Row className="d-flex justify-content-between align-items-center">
          <Col md={6}>
            <h1 className="page-title pb-0">My Questions</h1>
            <p style={{fontSize: '21px'}}>Check all questions that help you</p>
          </Col>
          <Col md={3}>
            <button
              type="button"
              className="card-button btn btn-primary"
              style={{padding: '15px 20px'}}
              onClick={handleClick}>
              Ask New Question
            </button>
          </Col>
        </Row>

        <BankQuestionsList />
      </Container>
    </div>
  );
};

export default UserBankQuestions;
