import { urlParamReplace } from '../../helpers/UrlParamReplace';
import { APIURL } from '../../services/http/api';
import { EApiParams } from '../../services/http/apiParams.enum';
import { HttpService } from '../../services/http/http.service';

const http = new HttpService();

export const getFamily = async url => {
  const api = APIURL.family.getFamily;
  const [err, resp] = await http.get(api);

  return {error: err, response: resp?.data};
};

export const addMembers = async data => {
  const [err, resp] = await http.post(APIURL.family.addMembers, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {error: err, response: resp.data};
};
export const editMembers = async (data, id) => {
  const api = APIURL.family.editMember;
  const finalApi = urlParamReplace(api, {
    [EApiParams.childId]: id,
  });
  const [err, resp] = await http.post(finalApi, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {error: err, response: resp.data};
};
export const deleteMember = async data => {
  const [err, resp] = await http.delete(APIURL.family.deleteMember, data);

  return {error: err, response: resp.data};
};
