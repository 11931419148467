export const getUserCurrency = () => {
  
    let currency =''
    
    let time_zone =  Intl.DateTimeFormat().resolvedOptions().timeZone
    if( time_zone == 'Africa/Cairo' ){
        currency = 'EGP'
    }else{
        currency = 'USD'
    }
    console.log('user currency' , currency )
    
    
    
    return currency
  
};
