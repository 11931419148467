import {NavLink, useNavigate} from 'react-router-dom';
import classes from '../style/ConsultantSessionsBtnActions.module.css';
import {colors} from '../../../constants/colors';
import toast, {Toaster} from 'react-hot-toast';
import {ConsultantSessionsBtnActionsProp} from '../interfaces/IConsultants';

function ConsultantSessionsBtnActions({
  consId,
  showFree,
  enableFree,
  availableFreeSessions,
  firstFree,
  navToFree,
  item,
}: ConsultantSessionsBtnActionsProp) {
  const navigate = useNavigate();
  let myId = localStorage.getItem('id');
  return (
    <>
      <Toaster position="top-right" />
      <div
        className={`${classes.ConsultantBtnAction}`}
        onClick={() => {
          navigate(`/chat/${myId}/${consId}`, {
            state: {
              current: myId,
              guest: consId,
              senderName: localStorage.getItem('name'),
              recName: item?.username,
              senderAvatar: localStorage.getItem('avatar'),
              recAvatar: item?.avatar,
              senderFCM: 'await getToken()',
              recFCM: item?.consultant_firebase_tokens,
            },
          });
        }}
        style={{background: '#0576e3'}}>
        <span>Contact Now</span>
        <img src="/chat.png" alt="chat" />
      </div>

      <NavLink
        className={`${classes.ConsultantBtnAction}`}
        to={`/consultants/request/private/session/${consId}`}
        style={{background: '#0576e3'}}>
        <span>Request Private Lesson</span>
        <img src="/edit.png" alt="chat" />
      </NavLink>
      {showFree && (
        <div
          className={`${classes.ConsultantBtnAction}`}
          onClick={() => {
            // navToFree();
            if (availableFreeSessions > 0 && firstFree == 2) {
              toast.error('You have took your free session');
            } else if (availableFreeSessions == 0) {
              toast.error('You have no more free sessions this month');
            } else {
              navToFree();
            }
          }}
          style={{
            background: enableFree ? colors.darkGreen : colors.regularGray,
          }}>
          <span>Book Free Lesson</span>
          <img src="/free.png" alt="chat" />
        </div>
      )}
    </>
  );
}

export default ConsultantSessionsBtnActions;
