import {Col, Container, Row} from 'react-bootstrap';
import {useAppDispatch} from '../../hooks/hooks';
import {useEffect, useState} from 'react';
import {setLoading} from '../../store/userSlice';
import {
  acceptRequestSession,
  getRequestsDetails,
  rejectRequestSession,
} from './Booking.service';
import {useParams} from 'react-router-dom';
import classes from './style/SessionDetails.module.css';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {getUserId} from '../../auth/utiles';

const PrivateSessionDetails = () => {
  const navigate = useNavigate();

  const getStatus = status => {
    if (status == 1) return 'Pending';
    else if (status == 2) return 'Accepted';
    else if (status == 3) return 'Rejected';
  };

  const {id, isCons} = useParams();
  const dispatch = useAppDispatch();

  const [item, setItem] = useState([]);
  const [dates, setDates] = useState([]);
  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    dispatch(setLoading(true));
    let result = await getRequestsDetails(id);
    console.log('result', result);
    if (result.response) {
      setItem(result.response?.data?.consultant_request_user);
      setDates(result.response?.data?.consultant_request_user_details);
      setTokens(result.response?.data?.user_request_tokens);
      console.log(
        'consultant_request_user',
        result.response?.data?.consultant_request_user,
      );
    }
    dispatch(setLoading(false));
  };

  const Accept = async () => {
    dispatch(setLoading(true));
    let result = await acceptRequestSession(id);

    if (result.response.status) {
      dispatch(setLoading(false));
      navigate(-1);
    }
  };

  const Reject = async () => {
    dispatch(setLoading(true));
    let result = await rejectRequestSession(id);

    if (result.response.status) {
      dispatch(setLoading(false));
      navigate(-1);
    }
  };

  return (
    <div>
      <Container className="mt-5">
        <Row>
          <Col md={{span: 8, offset: 2}}>
            <div
              style={{
                boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                borderRadius: '10px',
                padding: '3rem',
                background: '#f7faff',
              }}>
              <div className="mb-4">
                <h3>Reason</h3>
                <p>{item?.reason}</p>
              </div>

              <div className="mb-4">
                <h3>Status</h3>
                <h5
                  style={{
                    color:
                      item?.status == 1
                        ? '#f7c700'
                        : item?.status == 2
                        ? '#39B970'
                        : '#0576e3',
                  }}>
                  {getStatus(item?.status)}
                </h5>
              </div>

              <div className="mb-4">
                <h3 className="mb-3">Appropriate time and date</h3>
                {dates.map(item => {
                  return (
                    <div
                      style={{
                        background: '#fff',
                        padding: '2rem',
                        borderRadius: '15px',
                        marginBottom: '1rem',
                      }}>
                      <h4 className="secondry-color">
                        {moment(item.day).format('dddd')}
                      </h4>
                      <div className="mb-2">
                        <img
                          src={'/icons/calendar2.png'}
                          alt="course-img"
                          style={{
                            width: '20px',
                            marginRight: '10px',
                          }}
                        />
                        {`${moment(item.day).format('DD MMM YYYY')}`}
                      </div>

                      <div>
                        <img
                          src={'/icons/clock.png'}
                          alt="course-img"
                          style={{
                            width: '20px',
                            marginRight: '10px',
                          }}
                        />

                        {`${moment(item.from, 'HH:mm:ss').format(
                          'hh:mm A',
                        )} - ${moment(item.to, 'HH:mm:ss').format('hh:mm A')}`}
                      </div>
                    </div>
                  );
                })}
              </div>

              {item?.status == 1 && isCons == 1 ? (
                <div className={`${classes.ActionBtns} d-flex`}>
                  <button onClick={() => Reject()}>Decline</button>
                  <button onClick={() => Accept()}>Accept</button>
                </div>
              ) : (
                ''
              )}
              {isCons == 1 && item?.status == 2 && (
                <div
                  style={{
                    textAlign: 'center',
                    color: '#0576e3',
                    border: '2px solid',
                    padding: '15px',
                    borderRadius: '15px',
                    fontSize: '20px',
                    fontWeight: '500',
                  }}
                  onClick={() => {
                    const myId = getUserId();
                    navigate(`/chat/${myId}/${JSON.stringify(item?.user_id)}`, {
                      state: {
                        current: myId,
                        guest: JSON.stringify(item?.user_id),
                        senderName: localStorage.getItem('name'),
                        recName: item?.user_name,
                        senderAvatar: localStorage.getItem('avatar'),
                        recAvatar: item?.avatar,
                        senderFCM: 'await getToken()',
                        recFCM: tokens,
                      },
                    });
                  }}
                  className="mt-4">
                  Chat Now
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivateSessionDetails;
