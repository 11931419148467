import {useEffect, useRef, useState} from 'react';
import './LessonVideo.css';
import {finishLesson} from '../../../../Screens/Courses/Courses.service';
const LessonVideo = ({url, lessonId}: any) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    if (videoRef.current) {
      // Add an event listener when the component mounts
      videoRef.current.addEventListener('loadedmetadata', handleVideoLoad);

      // Clean up the event listener when the component unmounts
      return () => {
        if( videoRef ){
          videoRef.current!.removeEventListener(
            'loadedmetadata',
            handleVideoLoad,
          );
        }
        
      };
    }
  }, []);

  const handleVideoLoad = () => {
    if (videoRef.current) {
      videoRef.current.play(); // Start playing the video when metadata is loaded
    }
  };
  useEffect(() => {
    videoRef.current?.load();
  }, [url]);
  const endlesson = async () => {
    let data = new FormData();
    data.append(`lesson_id`, lessonId);
    await finishLesson(data);
  };
  return (
    <div>
      {/* <iframe
        className="youtube"
        src={url}
        width={'100%'}
        height={400}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        loading="lazy"></iframe> */}
      <video
        ref={videoRef}
        controls
        id="video"
        width={'100%'}
        height={400}
        onEnded={() => {
          endlesson();
        }}>
        <source src={url} type="video/mp4" />
        <source
          src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.ogg"
          type="video/ogg"
        />
        Your browser does not support HTML5 video.
      </video>
    </div>
  );
};

export default LessonVideo;
