import DatePicker from 'react-multi-date-picker';
import classes from '../style/MultiRangeCalendar.module.css';
import {MultiRangeCalendarProp} from '../interface/IAvailabilty';
import moment from 'moment';

const MultiSelectCalendar = ({
  setDays,
  selectedDays,
}: MultiRangeCalendarProp) => {
  const resetRangeCalendar = () => {
    setDays([]);
  };
  let minDate = moment().add('1', 'days').format('YYYY-MM-DD');
  let maxDate = moment().add('6', 'month').format('YYYY-MM-DD');

  return (
    <div className={classes.MultiRangeCalendar}>
      <div
        className={`d-flex justify-content-between align-items-center ${classes.selectDaysParagrah}`}>
        <div className="d-flex justify-content-between align-items-center">
          <img src="/icons/event.png" />
          {selectedDays.length >= 1 ? (
            <span> {selectedDays.length} Days Selected </span>
          ) : (
            <span>Select Multi Days</span>
          )}
        </div>
        <div className={classes.Clear} onClick={resetRangeCalendar}>
          Clear
        </div>
      </div>
      <DatePicker
        value={selectedDays}
        minDate={minDate}
        maxDate={maxDate}
        onChange={dateObjects => {
          setDays(dateObjects);
        }}
        multiple
      />
    </div>
  );
};

export default MultiSelectCalendar;
