import {Fragment} from 'react';
import {FaFacebookF, FaInstagram, FaLinkedin, FaTwitter, FaWhatsapp} from 'react-icons/fa';
import { MdOutlineEmail } from "react-icons/md";
import {NavLink} from 'react-router-dom';
import './Footer.css';
import {isUserLoggedIn} from '../../auth/utiles';
import {useAppSelector} from '../../hooks/hooks';
import {useTranslation} from 'react-i18next';
import { colors } from '../../constants/colors';

function Footer() {
  const {t} = useTranslation();
  const isCons = localStorage.getItem('isCons');
  const isLoggedIn = isUserLoggedIn();
  const consStatus = useAppSelector(state => state.user.consStatus);
  return (
    <Fragment>
      <div className="footer" id="footer"
      
      >
        <div className="container">
          <div className="row justify-content-between">
            

            <div className="col-md-3 mb-4">

            <div className="FooterLogo">
              <img src="/logo.png" alt="footerImg" />
            </div>

              
              <p className="aboutNurabi">{t('footerAboutDesc')}</p>
            </div>

            <div className="col-md-2 mb-4">
              <h4 className="FooterTitle">{t('Site Map')}</h4>
              <div className="Sitemap">

              {/*
              <p className="SiteLink">
                  <NavLink to="/user/initiatives/">{t('Competitions')}</NavLink>
                </p>
                */}
                <p className="SiteLink">
                  <NavLink to="/consultants/get-teachers">
                    {t('Teachers')}
                  </NavLink>
                </p>
                {(isCons !== 'true' || isCons == null || !isCons) &&
                  isLoggedIn &&
                  (consStatus == null || consStatus == 'rejected') && (
                    <p className="SiteLink">
                      <NavLink to="/user/consultation/request">
                        {t('join')}
                      </NavLink>
                    </p>
                  )}
                <p className="SiteLink">
                  <NavLink to="/courses">{t('Courses')}</NavLink>
                </p>
                {/*
                <p className="SiteLink">
                  <NavLink to="/events-list">{t('Events')}</NavLink>
                </p>
                */}

                {/*
                <p className="SiteLink">
                  <NavLink to="/user/trackings/list/">{t('Follow-up Systems')}</NavLink>
                </p>
                */}
                
                
                
              </div>
            </div>

            <div className="col-md-2 mb-4">
              <h4 className="FooterTitle">{t('Links')}</h4>
              <div className="Sitemap">
                <p className="SiteLink">
                  <NavLink to="https://apps.apple.com/eg/app/champions-%D8%AA%D8%B4%D8%A7%D9%85%D8%A8%D9%8A%D9%88%D9%86%D8%B2/id6456484767">
                    {t('App Store')}
                  </NavLink>
                </p>
                <p className="SiteLink">
                  <NavLink to="https://play.google.com/store/apps/details?id=com.championsacademy&pcampaignid=web_share">
                    {t('Play Store')}
                  </NavLink>
                </p>
                {!isLoggedIn && (
                  <div>
                    <p className="SiteLink mb-1">
                      <NavLink to="/login">{t('Login')}</NavLink>
                    </p>
                    <p className="SiteLink mb-1">
                      <NavLink to="/sign-up">{t('Register')}</NavLink>
                    </p>
                  </div>
                )}
                <p className="SiteLink">
                  <NavLink to="/contact-us">{t('Contact Us')}</NavLink>
                </p>
                <p className="SiteLink">
                  <NavLink to="/terms-and-conditions">
                    {t('Terms And Conditions')}
                  </NavLink>
                </p>
              </div>
            </div>

             {/*   
            <div className="col-md-2 mb-4">
              <h4 className="FooterTitle">{t('Download App')}</h4>
              <div className="Sitemap">
                
                <img
                  src="/champions-qr.jpeg"
                  className="mt-1"
                  style={{width: '100%', borderRadius: '15px', width: '100px'}}
                  alt="img"
                />
                
                 <p className='SiteLink'><NavLink to="/login">Login</NavLink></p>
                <p className='SiteLink'><NavLink to="/register">Register</NavLink></p>
              </div>
            </div>
            */}

            <div className="col-md-3 mb-md-4">
              <h4 className="FooterTitle">{t('Get In Touch With Us')}</h4>
              <div className="Sitemap">
                {/* <NavLink to="/">
                  <img
                    src="/contact/phone.png"
                    className="ContactImg"
                    alt="phone"
                  />
                </NavLink> */}
                
                
                <NavLink to="https://wa.me/+16478059910" className={'d-block mb-2 mt-3'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M8.76835 2.19193C9.79885 2.04993 10.6654 2.68043 11.0754 3.49993L12.0839 5.51643C12.6434 6.63543 12.3219 7.94843 11.4739 8.77443C10.9729 9.26193 10.4694 9.81893 10.1774 10.3104C10.1579 10.3454 10.1519 10.3863 10.1604 10.4254C10.4304 11.8884 11.4218 13.3339 12.3063 14.3704C12.351 14.4197 12.409 14.4548 12.4733 14.4714C12.5377 14.488 12.6055 14.4854 12.6684 14.4639L14.6559 13.8429C15.1912 13.6756 15.7661 13.6841 16.2963 13.8673C16.8265 14.0505 17.284 14.3988 17.6018 14.8609L19.0404 16.9534C19.4304 17.5209 19.6279 18.2909 19.3289 19.0314C19.0619 19.6929 18.5453 20.6469 17.6058 21.2814C16.6308 21.9394 15.2953 22.1894 13.5478 21.5904C11.5953 20.9204 9.75085 19.2404 8.22135 17.0919C6.68285 14.9299 5.41635 12.2294 4.64835 9.41343C3.92235 6.75493 4.36035 4.96493 5.39135 3.81243C6.38585 2.70043 7.81085 2.32343 8.76835 2.19193ZM9.95685 4.05893C9.72835 3.60243 9.32085 3.37693 8.93835 3.42943C8.08785 3.54643 7.02285 3.86293 6.32335 4.64543C5.66035 5.38693 5.20135 6.69293 5.85335 9.08443C6.58585 11.7684 7.79185 14.3334 9.23935 16.3669C10.6959 18.4134 12.3509 19.8584 13.9529 20.4079C15.3804 20.8974 16.2979 20.6554 16.9059 20.2454C17.5489 19.8109 17.9444 19.1204 18.1694 18.5634C18.2724 18.3084 18.2279 17.9784 18.0099 17.6614L16.5713 15.5694C16.4049 15.3273 16.1653 15.1449 15.8876 15.0489C15.6099 14.9529 15.3088 14.9483 15.0283 15.0359L13.0408 15.6569C12.4468 15.8424 11.7779 15.6769 11.3554 15.1819C10.4349 14.1034 9.26185 12.4494 8.93035 10.6519C8.86703 10.3151 8.92815 9.96664 9.10235 9.67143C9.48135 9.03443 10.0814 8.38493 10.6009 7.87893C11.1084 7.38493 11.2524 6.64993 10.9654 6.07543L9.95685 4.05893Z" fill="#0676E4"/>
                </svg>
                <span className='mx-2'>+16478059910</span>
                </NavLink>

                <NavLink to="mailto:nualim.platform@gmail.com" className={'d-block mb-2 mt-3'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M20.125 5H4.375C3.33947 5 2.5 5.83947 2.5 6.875V18.125C2.5 19.1605 3.33947 20 4.375 20H20.125C21.1605 20 22 19.1605 22 18.125V6.875C22 5.83947 21.1605 5 20.125 5Z" stroke="#0676E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.5 9L12.25 14.25L19 9" stroke="#0676E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <span className='mx-2'>nualim.platform@gmail.com</span>
                </NavLink>

                
                {/* <NavLink to="/">
                  <img
                    src="/contact/web.png"
                    className="ContactImg"
                    alt="web"
                  />
                </NavLink> */}
                {/* <NavLink to="/">
                  <img
                    src="/contact/location.png"
                    className="ContactImg"
                    alt="location"
                  />
                </NavLink> */}
              </div>
            </div>

            <div className="col-md-2 mb-4">
              <h4 className="FooterTitle">{t('Download App')}</h4>
              <div className="Sitemap">
                <img
                  src="/nualim-qr.jpeg"
                  className="mt-1"
                  style={{width: '100%', borderRadius: '15px', width: '100px'}}
                  alt="img"
                />
                {/* <p className='SiteLink'><NavLink to="/login">Login</NavLink></p>
                <p className='SiteLink'><NavLink to="/register">Register</NavLink></p> */}
              </div>
            </div>

            <div className="col-md-4 SocialIcons">
              <FaFacebookF
                className="SocialIcon"
                size={35}
                onClick={() => {
                  window.open(
                    'https://www.facebook.com/nualim.platform',
                    '_blank',
                  );
                }}
              />
              <FaInstagram
                className="SocialIcon"
                size={35}
                onClick={() => {
                  window.open(
                    'https://www.instagram.com/nualim.platform/',
                    '_blank',
                  );
                }}
              />
              
              
            </div>
          </div>
        </div>
      </div>
      <div className="CopyRight"
           
      >{t('Copyright © belongs to Nurabi')}</div>
    </Fragment>
  );
}

export default Footer;
