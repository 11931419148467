import Card from 'react-bootstrap/Card';
import courseImg from '../images/courses/course-img.png';
import CourseCardLink from './course-card-link/CourseCardLink';
import RateStars from './rate-stars/RateStars';
import './CourseCard.css';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

function CourseCard(props) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  function handleClick() {
    if (props.is_user == 0) {
      navigate('/login');
    } else {
      if (props.is_enrolled) {
        navigate(`/lessons/${props.id}`);
      } else {
        navigate(`/courses/${props.id}`);
      }
    }
  }

  return (
    <Card className="course-card" onClick={handleClick}>
      <p className="top-card-label">
        <span className="price-label">
          {props.is_live ? t('live') : t('recorded')}
        </span>

        {/*
        <span className='subscribed-label'>
          Subscribed for other
        </span>
        */}
      </p>
      <Card.Img variant="top" src={props.image ? props.image : courseImg} />
      <Card.Body>
        <Card.Title className="course-card-title card-custom-title mb-3">
          {props.title && props.title.length > 25
            ? props.title.slice(0, 25) + ' ...'
            : props.title}
        </Card.Title>
        <p className="text-muted">
          {props.short_short_description &&
          props.short_short_description.length > 25
            ? props.short_short_description.slice(0, 25) + ' ...'
            : props.short_short_description}
        </p>
        <Card.Text className="card-custom-provider">
          {props.provider_name}
        </Card.Text>
        <Card.Text
          style={{color: '#0576e3', fontSize: '18px', fontWeight: '700'}}>
          {!props.is_enrolled && props.price ? `${props.price} ${props.user_currency}` : t('Free')}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default CourseCard;
