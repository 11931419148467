import Header from "../../components/initiatives/Header"

const Initiatives = () => {

    return (
        <div>
            <Header />
        </div>
    )
}

export default Initiatives