import {Row, Tab, Tabs} from 'react-bootstrap';
import CourseFeedback from '../CourseFeedback';
import SourceViews from '../../../UI/source-views/SourceViews';
import '../style/LessonTabs.css';
import {FeedBack, LessonTabsProp} from '../interfaces/ICourses';
import {getFeedBacks} from '../Courses.service';
import {useEffect, useState} from 'react';
const LessonTabs = ({
  id,
  subscribers,
  description,
  rating_stars,
  ratings,
}: LessonTabsProp) => {
  const [feedBacks, setFeedBacks] = useState<FeedBack[]>([]);

  useEffect(() => {
    getFeedBacksFN();
  }, []);

  const getFeedBacksFN = async () => {
    let result = await getFeedBacks(id);
    if (result?.response) {
      setFeedBacks(result.response.data);
    }
  };
  return (
    <div className="lessonTabsContainer">
      <div className="lesson-views-container">
        <SourceViews numbers={subscribers} />
      </div>
      <Tabs
        defaultActiveKey="about"
        id="uncontrolled-tab-example"
        className="lesson-tabs mb-4">
        <Tab eventKey="about" title="About">
          <Row>
            <p className="aboutCourse">{description}</p>
          </Row>
        </Tab>
        <Tab eventKey="feedback" title="Feedbacks">
          <CourseFeedback
            feedback={feedBacks}
            ratings={ratings}
            rating_stars={rating_stars}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default LessonTabs;
