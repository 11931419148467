import classes from './spinner.module.css';
import courseImg from '../../images/33.png';

const LoadingSpinner = () => {
  return (
    <div className={`${classes.Container}`}>
      <img className={`${classes.LoadingSpinner}`} src={courseImg} />
    </div>
  );
};

export default LoadingSpinner;
