import './LessonVideo.css';
const Preview = ({url}: any) => { 
  return (
    <div>
      <video
        controls
        id="video"
        width={'100%'}
        height={400}
        >
        <source src={url} type="video/mp4" />
        <source src={url} type="video/ogg" />
        Your browser does not support HTML5 video.
      </video>
    </div>
  );
};

export default Preview;
