import {APIURL} from '../../services/http/api';
import {urlParamReplace} from '../../helpers/UrlParamReplace';
import {EApiParams} from '../../services/http/apiParams.enum';
import {HttpService} from '../../services/http/http.service';

const http = new HttpService();

export const getStory = async (
) => {
  const api = APIURL.Home.getStory;
  const [err, resp] = await http.get(api);
  return {error: err, response: resp?.data};
};

export const sendStory = async (data: FormData) => {
  const api = APIURL.Home.addStory;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const sendStoryReport = async (data: FormData) => {
  const api = APIURL.Home.sendStoryReport;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const sendComment = async (data: FormData) => {
  const api = APIURL.Home.sendComment;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const getStoryInformation = async (id: number | string) => {
  const api = APIURL.Home.getStoryInfo;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: id,
  });
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const sendEmoji = async (data: FormData) => {
  const api = APIURL.Home.sendEmoji;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const showStory = async (id: number | string) => {
  const api = APIURL.Home.showStory;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: id,
  });
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};


export const deleteStory = async (id: number | string) => {
  const api = APIURL.Home.deleteStory;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: id,
  });
  const [err, resp] = await http.delete(finalApi);
  return {error: err, response: resp?.data};
};