export const getDynamicLinkDomain = () => {
  
    let domainName =''
    let hostName = window.location.hostname

    if( hostName == 'localhost' || hostName == 'dev.nualim.com' ){
        domainName = 'https://dev.nualim.com'
        
        
    }

    else if( hostName == 'test.nualim.com' ){
        domainName = 'https://test.nualim.com'
    }

    else if(hostName == 'prod.nualim.com' || hostName == 'nualim.com'){
        domainName = 'https://nualim.com'
    }
    
    else{
        domainName = 'https://dev.nualim.com'
    }
    
    
    return domainName
  
};
