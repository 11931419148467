import classes from '../style/SessionCard.module.css';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
const SessionCard = ({name,avatar,created_at,status}) => {
  const navigate = useNavigate();
  const getStatus = status => {
    if (status == 1) return 'Pending';
    else if (status == 2) return 'Accepted';
    else if (status == 3) return 'Rejected';
    };

  return (
    <div
      className={`${classes.Item} d-flex row`}
      
     
     
      >
     
      <div className={`${classes.Owner}`}
      style={ { 
        right: 'initial' , 
        right : '0px' , 
        borderRadius: '0px 10px',
        backgroundColor:
                          status == 1
                            ? '#f7c700'
                            : status == 2
                            ? '#39B970'
                            : '#0576e3',
        } } >{getStatus(status)}</div>
     

      <div className={`${classes.Image} col-3 d-flex align-items-center`}>
      <img
          src={avatar ?? '/icons/manHolder.png' }
          alt="course-img"
          style={{
            width: '100px',
            height: '100px',
          }}
        />
      </div>
      <div
        className={`${classes.Details} col-8 d-flex flex-column justify-content-between`}>
         <h5 className="font-weight-bold p-0 mb-3">{name}</h5>  
        
        {`${moment(created_at)               
          .format('dddd  DD-MM-YYYY   hh:mm A')}`}
      </div>
    </div>
  );
};

export default SessionCard;
